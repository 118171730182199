import { Navigate,useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

const PrivateRoute =({element:Element,...rest})=>{
const {isAuthenticated}=useAuth0();
const location = useLocation();

return(
    isAuthenticated?(<Element {...rest}/>):(<Navigate to="/login" state={{from:location}} replace/>)
)

}
export default PrivateRoute;