import React from 'react';
import moment from 'moment';
import momentTime from 'moment-timezone';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...JSON.parse(JSON.stringify(oldObject)),
        ...updatedProperties,
    };
};


export const revampFormatTime = (minutes) => {
    const minutesInHour = 60;
    const minutesInDay = 1440; // 24 * 60 minutes
    const daysInMonth = 30;
    const daysInYear = 365;

    if (minutes >= daysInYear * minutesInDay) {
        const years = Math.floor(minutes / (daysInYear * minutesInDay));
        const remainingMonths = Math.floor(
            (minutes % (daysInYear * minutesInDay)) /
                (daysInMonth * minutesInDay),
        );
        return `${years}y ${remainingMonths}m`;
    }

    if (minutes >= 90 * minutesInDay) {
        const months = Math.floor(
            minutes / (daysInMonth * minutesInDay),
        );
        const remainingDays = Math.floor(
            (minutes % (daysInMonth * minutesInDay)) / minutesInDay,
        );
        return `${months}m ${remainingDays}d`;
    }

    if (minutes >= minutesInDay) {
        const days = Math.floor(minutes / minutesInDay);
        const hours = Math.floor(
            (minutes % minutesInDay) / minutesInHour,
        );
        return `${days}d ${hours}h`;
    }

    if (minutes >= minutesInHour) {
        const hours = Math.floor(minutes / minutesInHour);
        return `${hours}h`;
    }
    return typeof minutes === 'number' && !Number.isInteger(minutes) ? 
    `${minutes.toFixed(2)}m`  : `${minutes}m`;
};
export const monthDataSorting = (dates) => {
    dates.sort((a, b) => {
        // Convert date strings into timestamps (milliseconds since the Unix Epoch)
        const timestampA = new Date(a.shipment_time).getTime();
        const timestampB = new Date(b.shipment_time).getTime();
      
        // Compare the timestamps
        return timestampA - timestampB;
    });
    return dates
}

export const revampTonAndKtFlow = (value) => {
    const ton = 1000; // 1 ton = 1000 kg
    const kiloton = 1000000; // 1 kiloton = 1,000,000 kg

    const tons = (value / ton).toFixed(2);
    return `${tons} t`;
    // if (value >= kiloton) {
    //     const kilotons = (value / kiloton).toFixed(2);
    //     return `${kilotons} kt`;
    // } else if (value >= ton) {
    //     const tons = (value / ton).toFixed(2);
    //     return `${tons} t`;
    // } else {
    //     return `${value.toFixed(2)} kg`;
    // }
};

// function formatShipmentTime(dateString) {
//     const date = new Date(dateString);
//     const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
//     const month = monthNames[date.getMonth()];
//     return `${date.getFullYear()} ${month}`;
//   }
  
//   // Use the map() method to format the shipment_time property for each object
//   const formattedShipments = shipments.map(shipment => ({ ...shipment, shipment_time: formatShipmentTime(shipment.shipment_time) }));

export const replaceHyphensWithSlashes = (dateString) => {
    // Replace hyphens with forward slashes
    const formattedDate = dateString.replace(/-/g, '/');
    
    return formattedDate;
  }
  
export const gmtTimeDate =(date) => {
    const localTimestamp = new Date(date);
    const gmtTimestamp = new Date(localTimestamp.getTime() + localTimestamp.getTimezoneOffset() * 60 * 1000);
    return moment(gmtTimestamp).format('DD MMM YYYY, hh:mm a')
}
// export const getLocalTime = (date) => {
//     const utcDate = new Date(date);
//     // Convert the UTC date to a local timestamp
//     const localTimestamp = utcDate.getTime() + (utcDate.getTimezoneOffset() * 60 * 1000);
//     return moment(localTimestamp).format('DD/MMM/YYYY, hh:mm a')
// }

export const getDateFormat = (el) => {
    const date = moment(el, 'YYYY-MM-DD HH:mm:ss');
    const utcDate = date.utc();
    const formattedDate = utcDate.format('YYYY-MM-DD at HH:mm A z');
    return formattedDate;
}


export const getLocalTime = (date) => {
    const utcDate = new Date(date);
    const tz = momentTime.tz.guess(); // Guess the user's time zone
    return momentTime(utcDate).tz(tz).format('DD MMM YYYY, hh:mm a');
  };

// export const epochTimeConvertorConvertor = (dateOption, midNightFlag) => {

//     // const dateString = new Date(dateOption);
//     // if(midNightFlag) {
//     //     dateString.setHours(23)
//     //     dateString.setMinutes(59)
//     // } else {
//     //     dateString.setHours(0)
//     //     dateString.setMinutes(0)
//     // }\

//     // console.log("dateOption", dateOption)
//     // Z mean timestamp convertor into utc always 
//     let dateConverstionOnlyUTc =  new Date(`${dateOption}Z`)

//     let finalSeconds = dateConverstionOnlyUTc ? dateConverstionOnlyUTc.getTime()/1000 : null 

//     console.log("epochTimeConvertor",  finalSeconds)
//     // const epoch = dateOption ? Math.ceil(new Date(dateString)/1000)  : null
//     return finalSeconds ?? null;
// };

export const getUTCDate = (t, format) => {
    if(format === "eventCard") {
        return moment.utc(Number(t)).format('DD MMM YYYY, HH:mm:ss');
    }
    else if(format) {
        return moment.utc(Number(t)).format('DD MMM YYYY, HH:mm ');

    } else {
        return moment.utc(Number(t)).format('MM/DD/YYYY at HH:mm A').replace(/amt|pmt/, 'at');

    }
};


export const getComparisonYear = (date) => {
    return moment(date).format('DD MMM (YYYY)')
};

export const getComparisonYearRevamp= (timeInMS)=> {

    const date = timeInMS ? new Date(timeInMS * 1000) : null ;

    // Array of month names
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    // Format the UTC date as DD MMM (YYYY)
    const formattedDate =
    date? 
    `${String(date.getUTCDate()).padStart(2, '0')} ${monthNames[date.getUTCMonth()]} (${date.getUTCFullYear()})`
    : 
    ""

    return formattedDate

}

export const epochTimeConvertorConvertor = (dateOption, midNightFlag) => {

    // Z mean timestamp convertor into utc always 
    // let dateConverstionOnlyUTc =  
    // new Date(${dateOption}T00:00:00Z);
    // // new Date(${dateOption}Z)

    const localDate = new Date(dateOption);

    let utcDate = new Date(Date.UTC(
        localDate.getFullYear(),
        localDate.getMonth(),
        localDate.getDate(),
        localDate.getHours(),
        localDate.getMinutes(),
        localDate.getSeconds(),
        localDate.getMilliseconds()
      ));

    let finalSeconds = utcDate ? utcDate.getTime()/1000 : null 

    // const epoch = dateOption ? Math.ceil(new Date(dateString)/1000)  : null
    return finalSeconds ?? null;
};

export const getLocalTimeStamp = (t) => {
    return moment.utc(Number(t)).format('MM/DD/YYYY at HH:mm a').replace(/amt|pmt/, 'at');
};

export const formatTime = (milliseconds,useGraphFormat = false, timezone, format = 24, showMeridiem = false, showTimeZone=true, useDefault=true)=>{
    if(!milliseconds) return ""

    const userDetails = JSON.parse(localStorage.getItem("userDetails"))
    if(!timezone){
        if(userDetails.timezone){
            timezone=userDetails.timezone;
        }else{
            timezone="LOCAL"
        }
    }
    if (timezone.toLowerCase() === 'local') {
        timezone = momentTime.tz.guess();
    }
    let time = momentTime.tz(milliseconds, timezone);
    let dateFormat = "DD MMM YYYY HH:mm:ss";
    if (format === 12 && useDefault) {
        dateFormat = showMeridiem ? "DD:MMM:YYYY hh:mm:ss A" : "DD:MMM:YYYY hh:mm:ss";
    }

    if(useGraphFormat){
        dateFormat=format===12 ? 'MM/DD/YYYY hh:mm A' : 'MM/DD/YYYY hh:mm';
    }

    let timezoneAbbr = time.format('z')
    let formattedTime =showTimeZone ? time.format(dateFormat) + ` (${timezoneAbbr})`:time.format(dateFormat);
    return formattedTime;
}

export const getTimeZone =()=>{
    let guessedTimeZone = momentTime.tz.guess()
    let currentTime = new Date()
    let fullTimeZoneName = new Intl.DateTimeFormat('en-US', {
        timeZone:guessedTimeZone,timeZoneName:'long'
    }).formatToParts(currentTime).find(part=>part.type==='timeZoneName').value
    let cityName = guessedTimeZone.split('/')[1].replace('_','')
    return `${cityName} (${fullTimeZoneName})`
   
}

export const getDate = (t, returnDate, dontConvert, dontUseDefaultDate, onlyDate, showSeconds, obj, formatAs) => {
    let d = t;
    if(t && (t).toString().indexOf('GMT') >= 0 ) {
    } else if(t){
        d = (t).toString().indexOf('-') >=0 ? t.replace(/-/g, '/') : t
    }
    
    const T = d
        ? new Date(new Date(isNaN(d) ? d : Number(d)).getTime() - (dontConvert ? 0 : new Date().getTimezoneOffset() * 60 * 1000))
        : dontUseDefaultDate ? 0 : new Date();
        
    if(isNaN(new Date(T).getTime()) || T === 0) {
        return '--';
    }
    if (returnDate) {
        return T;
    }    
    const day = T.getDate();
    const month = T.getMonth();
    const year = T.getFullYear();
    const today = new Date();
    let connectingString = 'at';
    if (T.getTime() > today.getTime()) {
        connectingString = 'by';
    }

    if(onlyDate) {
        return moment(T).format(formatAs || 'MM/DD/YYYY');
    }
    if (day === today.getDate() && month === today.getMonth() && year === today.getFullYear()) {
        return `Today ${connectingString} ${moment(T).format(`HH:mm${showSeconds?':ss':''}`)}`;
    }
    const format = formatAs || `MM/DD/YYYY at HH:mm${showSeconds?':ss':''}`;
    if (T.getTime() > today.getTime()) {
        return moment(T)
            .format(format)
            .replace(/amt|pmt/, connectingString);
    }
    return moment(T)
        .format(format)
        .replace(/amt|pmt/, 'at');
};
// eslint-disable-next-line no-extend-native
String.prototype.format = function(hash) {
    let string = this,
        key;
    for (key in hash) {
        string = string.replace(new RegExp('\\{' + key + '\\}', 'gm'), hash[key]);
    }
    return string;
};
export const formatDate = d => {
    try {
        if (d === '' || d === null || d === undefined) return '--';
        return moment(d).format('MMM DD, YYYY');
    } catch (e) {
    }
};
export const formatPhone1 = v => {
    if (v === undefined || v === null) return '';
    const V = v + '';
    let val = V.replace(/[()\s]/g, '');
    if (isNaN(val)) return '';
    val = val.substr(0, 10);
    val = `(${val.substr(0, 3)}) ${val.substr(3, 3)} ${val.substr(6, 4)}`.trim().replace(/[)]$/, '');
    return val.length === 1 ? '' : val;
};
export const formatPhone = text => {
    let cleaned = ('' + text).replace(/\D/g, '').substr(0,19);
    let splitCleaned = [];
    if (cleaned && cleaned.length > 10) {
        splitCleaned = [cleaned.slice(0, 10), cleaned.slice(10)];

        cleaned = splitCleaned[0];
    }

    const match = cleaned.match(/^(\d{3})(\d{3})(\d{1,4})$/) || cleaned.match(/^(\d{3})(\d{1,3})(\d{0,4})$/);
    if (match) {
        var intlCode = `(${match[1]}) ${match[2]}${match[3]?`-${match[3]}`:''}`;
        return `${intlCode}${splitCleaned && splitCleaned.length > 0 ? splitCleaned[1] : ''}`;
    }

    return cleaned;
};
export const formatTemperature = (T, r) => {
    const rep = r || 'C';
    let t = T + '';
    if (!t || t === '' || t === undefined || t === null) return '';
    if(rep && (rep === 'F' || rep === 'f')) {
        t = toFahrenheit(t);
    }
    t = `${t}°${rep}`;
    if (t.includes('°')) {
        const temp = t.split('°');
        return `${parseFloat(temp[0], 10)
            .toFixed(1)
            .replace(/\.?0+$/, '')}°${temp[1]}`;
    } else if (t.includes(' ')) {
        const temp = t.split(' ');
        return `${parseFloat(temp[0], 10)
            .toFixed(1)
            .replace(/\.?0+$/, '')} ${temp[1]}`;
    } else {
        return `${parseFloat(t, 10)
            .toFixed(1)
            .replace(/\.?0+$/, '')}°${rep}`;
    }
};

export const toFahrenheit = t => {
    return Number(((Number(t) * 9) / 5 + 32).toFixed(2));
};
export const toCelsius = t => {
    return Number(((Number(t) - 32) * (5 / 9)).toFixed(2));
};

export const getFormattedDuration = MS => {
    if(MS === "NA" || MS === null || MS === undefined || isNaN(MS)) return 'NA';
    const ms = Math.abs(MS);
    const diff = moment.duration(ms)._data;
    let str = '';
    if (diff.years > 0) {
        str = `${diff.years} year${diff.years !== 1 ? 's' : ''} ${diff.months > 0 ? `and ${diff.months} month${diff.months !== 1 ? 's' : ''}` : ''}`;
    } else if (diff.months > 0) {
        str = `${diff.months} month${diff.months !== 1 ? 's' : ''} ${diff.days > 0 ? `and ${diff.days} day${diff.days !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.days > 0) {
        str = `${diff.days} day${diff.days !== 1 ? 's' : ''} ${diff.hours > 0 ? `and ${diff.hours + (diff.minutes>30 ? 1 : 0)} hour${diff.hours !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.hours > 0) {
        str = `${diff.hours} hour${diff.hours !== 1 ? 's' : ''} ${diff.minutes > 0 ? `and ${diff.minutes} min${diff.minutes !== 1 ? 's' : ''}`: ''}`;
    } else if (diff.minutes > 0) {
        str = `${diff.minutes} min${diff.minutes !== 1 ? 's' : ''}`;
    } else {
        str = `${diff.seconds > 0 ? '1 min' : '0 mins'}`;
    }
    return <span title={`${diff.years>0?`${diff.years}year${diff.years===1?'':'s'}`:''}${diff.months>0?` ${diff.months}month${diff.months===1?'':'s'}`:''}${diff.days>0?` ${diff.days}day${diff.days===1?'':'s'}`:''}${diff.hours>0?` ${diff.hours}hour${diff.hours===1?'':'s'}`:''}${diff.minutes>0?` ${diff.minutes}minute${diff.minutes===1?'':'s'}`:''}${diff.seconds>0?` ${diff.seconds}second${diff.seconds===1?'':'s'}`:''}`}>{str}</span>;
};
export const getFormattedDurationShort = (MS, toolTip) => {
    if(MS === "NA" || MS === null || MS === undefined || isNaN(MS)) return 'NA';
    const ms = Math.abs(MS);
    //const diff = moment.duration(ms)._data;
    const diff = {years:0,months:0,days:0,hours:0,minutes:0,seconds:0};
    const TIME_OBJ = {year: 12*30*24*60*60*1000, month: 30*24*60*60*1000, day: 24*60*60*1000, hour: 60*60*1000, minute: 60*1000, second: 1000}
    diff.years = Math.floor(ms/(TIME_OBJ.year));
    diff.months = Math.floor(ms%(TIME_OBJ.year)/TIME_OBJ.month);
    diff.days = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month/(TIME_OBJ.day));
    diff.hours = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)/(TIME_OBJ.hour));
    diff.minutes = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)%(TIME_OBJ.hour)/(TIME_OBJ.minute));
    diff.seconds = Math.floor(ms%(TIME_OBJ.year)%TIME_OBJ.month%(TIME_OBJ.day)%(TIME_OBJ.hour)%(TIME_OBJ.minute)/(TIME_OBJ.second));
    let str = '';
    if (diff.years > 0) {
        str = `${diff.years}y${diff.months > 0 ? ` ${diff.months}m` : ''}`;
    } else if (diff.months > 0) {
        str = `${diff.months}m${diff.days > 0 ? ` ${diff.days}d`: ''}`;
    } else if (diff.days > 0) {
        str = `${diff.days+(diff.hours>22&&diff.minutes>30?1:0)}d${diff.hours > 0 ? ` ${diff.hours>22&&diff.minutes>30?'':`${diff.hours + (diff.minutes>30 ? 1 : 0)}h`}`: ''}`;
    } else if (diff.hours > 0) {
        str = `${diff.hours}h ${diff.minutes > 0 ? ` ${diff.minutes}m`: ''}`;
    } else if (diff.minutes > 0) {
        str = `${diff.minutes}m${diff.minutes !== 1 ? '' : ''}`;
    } else {
        str = `${diff.seconds > 0 ? '1m' : '0m'}`;
    }
    if(toolTip){
        return <span className='t-tip t' style={{color: '#000'}}>{str}<span className='t-txt'>{diff.years>0?`${diff.years}y`:''}{diff.months>0?` ${diff.months}m`:''}{diff.days>0?` ${diff.days}d`:''}{diff.hours>0?` ${diff.hours}h`:''}{diff.minutes>0?` ${diff.minutes}m`:''}{diff.seconds>0?` ${diff.seconds}s`:''}</span></span>;
    }else{
        return <span title={`${diff.years>0?`${diff.years}y${diff.years===1?'':'s'}`:''}${diff.months>0?` ${diff.months}m${diff.months===1?'':'s'}`:''}${diff.days>0?` ${diff.days}d${diff.days===1?'':'s'}`:''}${diff.hours>0?` ${diff.hours}h${diff.hours===1?'':'s'}`:''}${diff.minutes>0?` ${diff.minutes}m${diff.minutes===1?'':'s'}`:''}${diff.seconds>0?` ${diff.seconds}s${diff.seconds===1?'':'s'}`:''}`}>{str}</span>;
    }
};
export const excludeZipCountry = i => {
    if (i && typeof i.toLowerCase() === 'string') return `${i}`.replace(/(?:(\s\d{1,},.[^,]+$)|(,[^,])$)/gi, '').replace(/(\s{0,},\s{0,},)/,',');
    return i;
};
export const excludeDoubleComma = i => {
    if (i && typeof i.toLowerCase() === 'string') return `${i}`.replace(/(\s{0,},\s{0,},)/,',');
    return i;
};


export const enableETA = () => {
    const R = localStorage.getItem('roles');
    const isCarrierTracking = localStorage.getItem('carrierTracking') === '1';
    return R && (R.toLowerCase() === 'super admin' || isCarrierTracking) ? true : false;
}
export const roundOff = (n, p) => {
    const P = isNaN(p) ? 0 : p;
    return +(Math.round(Number(n) + "e+" + P)  + "e-" + P)
}

const timeMap = {
    0: 'morning',
    1: 'afternoon',
    2: 'evening',
};
export const setTimeOfDay = () => {
    const D = new Date().getHours();
    const index = [12, 16, 24].findIndex(t => D < t);
    this.setState({ timeOfDay: timeMap[index] });
};


export const getSplitTime = (timeInSeconds, returnParam, returnHTML, returnString) => {
    const splitTime = {days: 0, hours: 0, minutes: 0};

    splitTime.days = parseInt(timeInSeconds / (24 * 60 * 60));
    const leftOverTimeAfterDaysSplit = timeInSeconds - ( splitTime.days * 24 * 60 * 60);

    splitTime.hours = parseInt(leftOverTimeAfterDaysSplit / (60 * 60));
    const leftOverTimeAfterHoursSplit = timeInSeconds - ((splitTime.days * 24* 60 * 60) + (splitTime.hours*60*60));

    splitTime.minutes = parseInt(leftOverTimeAfterHoursSplit / (60));

    splitTime.seconds = timeInSeconds - ((splitTime.days * 24 * 60 * 60) + (splitTime.hours*60*60) + (splitTime.minutes*60));

    if(returnParam) {
        return splitTime[returnParam];
    } else if (returnHTML) {
        return (
        <>
        {splitTime.days > 0 ?
            <span className={`ex-time d${splitTime.days===1?' o':''}`}>{splitTime.days}</span>
        : '' }
        {splitTime.hours > 0 ?
            <span className={`ex-time h${splitTime.hours===1?' o':''}`}>{splitTime.hours}</span>
        : '' }
        {((splitTime.days === 0 || splitTime.hours === 0) && splitTime.minutes > 0) ?
            <span className={`ex-time m${splitTime.minutes===1?' o':''}`}>{splitTime.minutes}</span>
        : ''}
        {splitTime.days === 0 && splitTime.hours === 0 && splitTime.minutes === 0 && splitTime.seconds > 0 ?
            <span className={`ex-time s${splitTime.seconds===1?' o':''}`}>{splitTime.seconds}</span>
        : ''}
        {splitTime.days === 0 && splitTime.hours === 0 && splitTime.minutes === 0 && splitTime.seconds === 0 ?
            <span className={`ex-time m`}>{splitTime.minutes}</span>
        : ''}
        </>
        );
    } else if (returnString) {
        const obj = [];
        if(splitTime.days > 0) {
            obj.push( `${splitTime.days}day${splitTime.days > 1 ? 's' : ''}` );
        }
        if(splitTime.hours > 0) {
            obj.push( `${splitTime.hours}hour${splitTime.hours > 1 ? 's' : ''}` );
        }
        if((splitTime.days === 0 || splitTime.hours === 0) && splitTime.minutes > 0) {
            obj.push( `${splitTime.minutes}minute${splitTime.minutes > 1 ? 's' : ''}` );
        }
        if((splitTime.days === 0 && splitTime.hours === 0 && splitTime.minutes === 0) && splitTime.seconds > 0) {
            obj.push( `${splitTime.seconds}second${splitTime.seconds > 1 ? 's' : ''}` );
        }
        return obj.join(' ');
    }

    return splitTime;
};

export const getFullTime = (splitTimeObj) => {
    let fullTime = 0;
    if(splitTimeObj?.mo && !isNaN(splitTimeObj.mo)) {
        fullTime = parseInt(splitTimeObj.mo, 10) * (30 * 24 * 60 * 60);
    }
    if(splitTimeObj?.d && !isNaN(splitTimeObj.d)) {
        fullTime += parseInt(splitTimeObj.d, 10) * (24 * 60 * 60);
    }
    if(splitTimeObj?.h && !isNaN(splitTimeObj.h)) {
        fullTime += parseInt(splitTimeObj.h, 10) * (60 * 60);
    }
    if(splitTimeObj?.m && !isNaN(splitTimeObj.m)) {
        fullTime += parseInt(splitTimeObj.m, 10) * (60);
    }
    if(!isNaN(splitTimeObj.s)) {
        fullTime += parseInt(splitTimeObj.s, 10);
    }
    return fullTime;
}

export const getSplitTimeConfig = (timeInSec, returnType) => {
    const timeInSeconds = timeInSec; /* || ((1*23*60*60) + (5*60*60) + (20*60) + (59))*/

    const splitTime = {mo: 0, d: 0, h: 0, m: 0, s: 0};

    splitTime.mo = parseInt(timeInSeconds / (30 * 24 * 60 * 60));
    const leftOverTimeAfterMonthsSplit = timeInSeconds - ( splitTime.mo * 30 * 24 * 60 * 60);

    splitTime.d = parseInt(leftOverTimeAfterMonthsSplit / (24 * 60 * 60));
    const leftOverTimeAfterDaysSplit = timeInSeconds - (( splitTime.mo * 30 * 24 * 60 * 60) + ( splitTime.d * 24 * 60 * 60));

    splitTime.h = parseInt(leftOverTimeAfterDaysSplit / (60 * 60));
    const leftOverTimeAfterHoursSplit = timeInSeconds - (( splitTime.mo * 30 * 24 * 60 * 60) + (splitTime.d * 24* 60 * 60) + (splitTime.h*60*60));

    splitTime.m = parseInt(leftOverTimeAfterHoursSplit / (60));

    splitTime.s = timeInSeconds - (( splitTime.mo * 30 * 24 * 60 * 60) + (splitTime.d * 24 * 60 * 60) + (splitTime.h*60*60) + (splitTime.m*60));
    
    return returnType ? splitTime[returnType] : splitTime;
};

export const positiveOrNegative = (finalValue) => {
    return finalValue === 0 ? "" : Math.sign(finalValue) === 0 || Math.sign(finalValue) === 1 ? "+" : "-"
}


export const waypointOrSegmentCheck = (value) => {
    
    const checkFlagValue = value.toLowerCase()==='static'||
        value.toLowerCase()==='waypoint'?   
        'waypoint': 
        value.toLowerCase()==='segment'||value.toLowerCase()==='land'||value.toLowerCase()==='road'||value.toLowerCase()==='air'||
        value.toLowerCase()==='ocean' || 
        value.toLowerCase()==='water'? 'segment':''

    return checkFlagValue;
}

export const getDateFromDurationTimeline = (obj) => {
    const dateTimeStamp = getDate(obj.date, true).getTime();
    const duration_num = Number(obj.duration);
    const durationDateTimeStamp = dateTimeStamp + (duration_num * 1000);
    const durationDateTime = moment(durationDateTimeStamp).format("MM-DD-YYYY HH:mm")

    return durationDateTime;

}

export const getDateFromDuration = (date, duration, dontConvert) => {
    const createdDate = getDate(date, true, dontConvert).getTime();
    const T = new Date(createdDate + (Number(duration) * 1000));
    const today = new Date();
    let connectingString = 'at';
    const day = T.getDate();
    const month = T.getMonth();
    const year = T.getFullYear();

    if (T.getTime() > today.getTime()) {
        connectingString = 'by';
        if (day === today.getDate() && month === today.getMonth() && year === today.getFullYear()) {
            return `Today ${connectingString} ${moment(T).format('HH:mm')}`;
        }
    }

    return moment(T)
        .format('MM/DD/YYYY at HH:mm')
        .replace(/amt|pmt/, connectingString);
}

export const getShortCode = (locationObj) => {
    let locationShortCode = '';
    let city = '';
    let state = '';
    if (locationObj && locationObj.context) {
        for (let index = 0; index < locationObj.context.length; index++) {
            const element = locationObj.context[index];
            if (element.id && element.id.includes('place')) {
                city = element.text;
            } else if (element.id && element.id.includes('region')) {
                state = element.text;
            }
            if (element.short_code) {
                // locationShortCode = element.short_code;
                let split_shortCode = '';
                if (element.short_code.includes('-')) {
                    split_shortCode = element.short_code.split('-');
                    locationShortCode = split_shortCode[0];
                    console.log('short code of contains -->', locationShortCode);
                } else {
                    locationShortCode = element.short_code;
                }
            }
        }
    } else {
        console.log(
            'short code of contains -->',
            'Context object is not available',
        );
    }
    if (locationShortCode === '') {
        locationShortCode = locationObj.properties?.short_code || '';
    }
    
    let locToString = '';
    if (city.length !== 0 && state.length !== 0) {
        locToString = city + ', ' + state;
    } else if (city.length === 0 && state.length !== 0) {
        locToString = locationObj.text + ', ' + state;
    } else {
        locToString = locationObj.text + ', ' + locationShortCode;
    }
    console.log('final Short Code: ', locToString);
    return locToString;
}

export const checkValidRelevance = (locations) => {
    let isRelevantLocation = false;
    for(let loc=0; loc<locations.length; loc++) {
        if(locations[loc].relevance >= 1) {
            isRelevantLocation = true;
            break;
        }
    }
    return isRelevantLocation;
}

export const dataToCsvURI = (data, name) => {
    const csvContent = `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURIComponent(data.map(row =>  row.join(',')).join(`\n `))}`;
    const link = document.createElement("a");
    link.setAttribute("href", csvContent);
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    if(link.remove) link.remove();
}

export const getDecimalNumber = (n, decimal, abs) => {
    const d = decimal || 1;
    let number = n;
    if(isNaN(number)) number = 0;
    let v = number % 1 === 0 ? parseInt(number,10) : Number(Number(number).toFixed(d)) === 0 ? 0 : Number(number).toFixed(d);
    if(abs) {
        v = Math.abs(v);
    }
    return v;
};

export const showDottedText = (txt, len=8) => {
    if(txt && txt.length>len) {
        return { txt: `${txt.substring(0,2)}...${txt.substring(txt.length-4)}`, clipped: true};
    }
    return {txt:txt?txt:'---', clipped: false};
};

export const getTotalMonths = (sDate, eDate) => {
    let endDate = new Date(eDate||'');
    let startDate = new Date(sDate||'');
    return ((endDate.getMonth() - startDate.getMonth()) + (12 * (endDate.getFullYear() - startDate.getFullYear()))) || 1;
};

export const getMonthName = (m) => new Date(`${m||1}/01/1900`).toLocaleString('default', { month: 'long' });

export const mergeObjects = (obj1, obj2) => {
    const merged = { ...obj1 };

    for (const key in obj2) {
        if (typeof obj2[key] === 'object' && obj2[key] !== null && !Array.isArray(obj2[key])) {
            merged[key] = mergeObjects(obj1[key], obj2[key]);
        } else {
            merged[key] = obj2[key];
        }
    }

    return merged;
};

export const generateRiskInsightsObj = ({Insights, insightsMapping, showInsights, duration, risk_factor, start_at, updated_at, compareLanesObj, insightsRangeFilter, transport_modes}) => {
    const formattedInsights = {};
    // console.log('=====================================');
    // console.log('Insights............---------------: ', Insights);
    // console.log('duration............---------------: ', duration);
    // console.log('=====================================');
    let durationRange = '-- — --';
    const durationRanges = {all:null, year:null, halfYear:null, quarter:null, month:null};
    const durationMonthMappingQ = {q1: 'January 1 - March 31', q2: 'April 1 - June 30', q3: 'July 1 - September 30', q4: 'October 1 - December 31'};
    const durationMonthMappingH = {h1: 'January 1 - June 30', h2: 'July 1 - December 31'};
    const durationRangesOptions = {year:{}, halfYear:{}, quarter:{}, month:{}};
    let durationOptionSelection = {};
    
    let comparisonString = '--';
    let lastSyncMonth = updated_at?new Date(updated_at*1000).getMonth():new Date().getMonth();
    //console.log('>>>updated_at: ', updated_at, '::::: ', updated_at?new Date(updated_at*1000).getMonth():new Date().getMonth());
    let lastSyncYear = updated_at?new Date(updated_at*1000).getFullYear():new Date().getFullYear();
    let rangeFilter = {month: false, quarter: false, halfYear: false, year: false};
    const ZERO_PARAM_LIST = ['avg_excursion_count_per_shipment','avg_excursion_duration_per_shipment','shipment_count', 'excursion_shipment_count', 'in-transit_delays', "delayed_shipment_count", "delayed_shipment_count_transport_mode"];

    for(let lane_r_or_l in Insights) {
        if(compareLanesObj) {
            //console.log('compareLanesObj, lane_r_or_l=======> ',compareLanesObj, lane_r_or_l);
            let loopIndex = 0;
            if(showInsights === 'lane') {
                loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(lane_r_or_l));
            } else if (showInsights === 'route') {
                const obj = lane_r_or_l.split('_');
                loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(obj[0]) && Number(lane.route_id) === Number(obj[1]));
            } else if (showInsights === 'waypoint') {
                const obj = lane_r_or_l.split('_');
                loopIndex = compareLanesObj.findIndex(lane => Number(lane.id) === Number(obj[0]) && Number(lane.route_id) === Number(obj[1]) && Number(lane.leg_id) === Number(obj[2]));
            }
            const u_at = compareLanesObj[loopIndex]?.obj.updated_at||null;
            lastSyncMonth = u_at?new Date(u_at*1000).getMonth():new Date().getMonth();
            lastSyncYear = u_at?new Date(u_at*1000).getFullYear():new Date().getFullYear();
        }
    }

    if(Insights && Object.values(Insights) && Object.values(Insights)[0] && Object.keys(Object.values(Insights).flat())?.length) {
        for(let lane_r_or_l in Insights) {
            
            formattedInsights[lane_r_or_l] = {};
            if(lane_r_or_l){} else return {};
            Object.keys(Insights[lane_r_or_l]).forEach(riskFactor => {
                const compareObj = showInsights === 'lane' ? 'overview' : riskFactor;
                if(risk_factor === '' || risk_factor === riskFactor || (risk_factor === 'routing_risk' && riskFactor === 'in-transit_delays' && showInsights === 'waypoint')) {
                    //if(duration !== 'ALL') {
                        if(Insights[lane_r_or_l][compareObj]) {
                            for(let key in Insights[lane_r_or_l][compareObj]) {
                                if(!isNaN(key)) {
                                    /*360D*/
                                    //if(duration === '360D') {
                                        durationRangesOptions.year[key] = durationRangesOptions.year[key] || {};
                                        const values = {...durationRangesOptions.year[key].values};
                                        values[lane_r_or_l] = {...values[lane_r_or_l], ...Insights[lane_r_or_l][compareObj][key]};
                                        durationRangesOptions.year[key] = {label: key, id: key, durationRange: [new Date(`1/1/${key}`).getTime(), new Date(`12/31/${key}`).getTime()], values};
                                        if(!rangeFilter.year) {
                                            rangeFilter.year = true;
                                        }
                                    //} else {
                                        for(let sub_key in Insights[lane_r_or_l][compareObj][key]) {
                                            if(isNaN(sub_key) && !Object.values(Insights[lane_r_or_l][compareObj][key][sub_key]).every(element => element === 'null'||element === null)) {
                                                let id = `${sub_key}_${key}`;
                                                if(sub_key.indexOf('h')===0) { /*180D*/
                                                    durationRangesOptions.halfYear[id] = durationRangesOptions.halfYear[id] || {};
                                                    const halfYearRange = durationMonthMappingH[sub_key];
                                                    let monthSplit = halfYearRange.split(' - ');
                                                    const durationRange = [new Date(`${monthSplit[0]} ${key}`).getTime(), new Date(`${monthSplit[1]} ${key}`).getTime()];
                                                    let values = {...durationRangesOptions.halfYear[id].values};
                                                    values[lane_r_or_l] = {...values[lane_r_or_l], ...Insights[lane_r_or_l][compareObj][key][sub_key]};
                                                    durationRangesOptions.halfYear[id] = {label: `${halfYearRange} (${key})`, id, durationRange, values};
                                                    if(!rangeFilter.halfYear) {
                                                        rangeFilter.halfYear = true;
                                                    }
                                                } else if(sub_key.indexOf('q')===0) { /*90D*/
                                                    durationRangesOptions.quarter[id] = durationRangesOptions.quarter[id] || {};
                                                    const quarterYearRange = durationMonthMappingQ[sub_key];
                                                    let monthSplit = quarterYearRange.split(' - ');
                                                    const durationRange = [new Date(`${monthSplit[0]} ${key}`).getTime(), new Date(`${monthSplit[1]} ${key}`).getTime()];
                                                    let values = {...durationRangesOptions.quarter[id].values};
                                                    values[lane_r_or_l] = {...values[lane_r_or_l], ...Insights[lane_r_or_l][compareObj][key][sub_key]};
                                                    durationRangesOptions.quarter[id] = {label: `${sub_key.toUpperCase()} (${key}): ${quarterYearRange}`, id, durationRange, values};
                                                    if(!rangeFilter.quarter) {
                                                        rangeFilter.quarter = true;
                                                    }
                                                } else if(sub_key.indexOf('month')===0) { /*30D*/
                                                    durationRangesOptions.month[id] = durationRangesOptions.month[id] || {};
                                                    const durationRange = [new Date(key, Number(sub_key.replace('month_',''))-1, 1).getTime(), new Date(key, sub_key.replace('month_',''), 0).getTime()];
                                                    let values = {...durationRangesOptions.month[id].values};
                                                    values[lane_r_or_l] = {...values[lane_r_or_l], ...Insights[lane_r_or_l][compareObj][key][sub_key]};
                                                    durationRangesOptions.month[id] = {label: `${getMonthName(sub_key.replace('month_',''))} (${key})`, id , durationRange, values};
                                                    if(!rangeFilter.month) {
                                                        rangeFilter.month = true;
                                                    }
                                                }
                                            }                        
                                        }
                                    //}
                                }                
                            }
                        }
                    //}

                    if(Insights[lane_r_or_l][compareObj]?.all_time) {
                        for(let key in Insights[lane_r_or_l][compareObj].all_time) {
                            if(duration === 'ALL') {
                                if(insightsMapping[key]) {
                                    formattedInsights[lane_r_or_l][insightsMapping[key]] = Insights[lane_r_or_l][compareObj].all_time[key];
                                    if(Number(key)===7 && showInsights === 'waypoint') {
                                        formattedInsights[lane_r_or_l][insightsMapping[9]] = Insights[lane_r_or_l][compareObj].all_time[key];
                                        formattedInsights[lane_r_or_l]['transport_mode'] = transport_modes[lane_r_or_l];
                                    }
                                } else {
                                    formattedInsights[lane_r_or_l][key] = Insights[lane_r_or_l][compareObj].all_time[key];
                                }
                            }
                        }
                    }
                }
            });
        }
    }

    // /*set the comparisonString, durationRange, durationRanges, durationOptionSelection*/
    if(duration === 'ALL'){
        comparisonString = 'No comparison is displayed when all data is selected.';
        if(start_at&&updated_at) durationRanges.all = {start: new Date(`${getDate((start_at || ''), false, true, false, true, false, true)}`).getTime(), end: new Date(`${getDate((updated_at*1000 || ''), false, true, false, true, false, true)}`).getTime()};
        durationRange = `${getDate((start_at || ''), false, true, false, true, false, true)} — ${getDate((updated_at*1000 || ''), false, true, false, true, false, true)}`

    }

    return {insights: formattedInsights, durationRange, rangeFilter:duration==='ALL'?rangeFilter:insightsRangeFilter, comparisonString, durationRanges, durationRangesOptions, durationOptionSelection};
};
export const validateInsightValue = (obj, key, validateDeviation, checkIsEqual, consider0AsValid) => {
    let isValid = true;
    if((
        (consider0AsValid?(obj[key]===undefined||obj[key]===null||obj[key]==='null'):(!obj[key] || obj[key] === 'null')) || 
        (validateDeviation ? (consider0AsValid?(obj[`${key}_deviation`]===undefined||obj[`${key}_deviation`]===null||obj[`${key}_deviation`]==='null'):(!obj[`${key}_deviation`] || obj[`${key}_deviation`] === 'null')): false) || 
        (checkIsEqual?(!isNaN(obj[key])||!isNaN(obj[`${key}_deviation`]))&&(obj[key]!==obj[`${key}_deviation`]):false)
    )) isValid = false;
    return isValid;
}
export const getShortMonth = (m) => {
    return new Date(`${m}/1/${new Date().getFullYear()}`).toLocaleString('default', { month: 'short' })
}

export const convertMillisecondsToDate = (milliseconds) => {
    let date = new Date(milliseconds);
    
    let day = String(date.getDate()).padStart(2, '0');
    let monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    
    let hours = String(date.getHours()).padStart(2, '0');
    let minutes = String(date.getMinutes()).padStart(2, '0');
    let seconds = String(date.getSeconds()).padStart(2, '0');
    
    return `${day} ${month} ${year}, ${hours}:${minutes}:${seconds}`;
}
export const isWithinRange = (expiryTimeDuration, lastActivityTime) => {
    var currentEpoch = new Date().getTime(); 
    var inactivityInSeconds = currentEpoch - lastActivityTime;
    // console.log("**********isWithinRange*************",inactivityInSeconds <= (expiryTimeDuration*1000));
    return inactivityInSeconds <= (expiryTimeDuration*1000);
} 


export const capitalizeFirstLetter =(string) => {
    return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : string
}


export const millisecondsIntoDate = (milliseconds) => {
    const date = new Date(milliseconds);
    console.log(`Year12: ${date.getFullYear()}`);
    console.log(`Month12: ${date.getMonth() + 1}`);
    console.log(`Date12: ${date.getDate()}`);
    return  new Date(date.getFullYear(), date.getMonth() + 1, date.getDate())
}

// export const getOptionsForExternalEvents = (data) => {
//     const events = Object.keys(data);
//     const eventsValue = Object.values(data);

//     const rangeFilter = {month: false, quarter: false, halfYear: false, year: false};
//     const durationMonthMappingQ = {q1: 'January 1 - March 31', q2: 'April 1 - June 30', q3: 'July 1 - September 30', q4: 'October 1 - December 31'};
//     const durationMonthMappingH = {h1: 'January 1 - June 30', h2: 'July 1 - December 31'};
//     const durationRangesOptions = {year:{}, halfYear:{}, quarter:{}, month:{}};
//     // const eventValue = {air:{}, marine:{}, geo:{}, weather: {}}
//     events.map((event, index) => {
//         Object.keys(data[event]).map((year) => {
//             if(year === 'all_time') return false;
//             durationRangesOptions.year = durationRangesOptions.year || {};
//             durationRangesOptions.year[year] = {
//                 label: year, id: year, 
//                 durationRange: [new Date(`1/1/${year}`).getTime(), new Date(`12/31/${year}`).getTime()]
//                 // [event] : eventsValue[index].all_time
//             };
//             if(!rangeFilter.year) {
//                 rangeFilter.year = true;
//             }
//             Object.keys(data[event][year]).map((range) => {
//                 const id = `${range}_${year}`;
//                 if(range.indexOf('h')===0) {
//                     durationRangesOptions.halfYear = durationRangesOptions.halfYear || {};

//                     const halfYearRange = durationMonthMappingH[range];
//                     let monthSplit = halfYearRange.split(' - ');

//                     durationRangesOptions.halfYear[id] = {
//                         durationRange : 
//                         [new Date(`${monthSplit[0]} ${year}`).getTime(),
//                          new Date(`${monthSplit[1]} ${year}`).getTime()],
//                         label: `${durationMonthMappingH[range]} (${year})`, id: `${range}_${year}`};
//                     if(!rangeFilter.halfYear) {
//                         rangeFilter.halfYear = true;
//                     }
//                 } else if(range.indexOf('q')===0) {

//                     const quarterYearRange = durationMonthMappingQ[range];
//                     let monthSplit = quarterYearRange.split(' - ');

//                     durationRangesOptions.quarter = durationRangesOptions.quarter || {};
//                     durationRangesOptions.quarter[id] = {
//                         durationRange : 
//                         [new Date(`${monthSplit[0]} ${year}`).getTime(),
//                          new Date(`${monthSplit[1]} ${year}`).getTime()],
//                         label: `${range.toUpperCase()} (${year}): ${durationMonthMappingQ[range]}`, id: `${range}_${year}`};
//                     if(!rangeFilter.quarter) {
//                         rangeFilter.quarter = true;
//                     }
//                 } else if(range.indexOf('month')===0) {
//                     durationRangesOptions.month = durationRangesOptions.month || {};
//                     durationRangesOptions.month[id] = {
//                         durationRange : 
//                         [new Date(year, Number(year.replace('month_',''))-1, 1).getTime(), 
//                             new Date(year, year.replace('month_',''), 0).getTime()],
//                         label: `${getMonthName(range.replace('month_',''))} (${year})`, id: `${range}_${year}`};
//                     if(!rangeFilter.month) {
//                         rangeFilter.month = true;
//                     }
//                 }
//             });
//         });
//     });
//     return {durationRangesOptions, rangeFilter};
// }

export const fahrenheitToCelsius = (fahrenheit) => {
    let celsius = 0
    if(fahrenheit) {
        celsius = (fahrenheit - 32) * 5/9;
    }

    return celsius === 0 ? 0 : celsius.toFixed(2)
}

export const formatCO2 = (value) => {
    if(value) {
        const co2Value = value / 1000;
        const formattedValue = parseFloat(co2Value.toFixed(2));
        return formattedValue;
    } else {
        return null
    }
}

export const formatUTCDate = (utcString) => {
    const [datePart, timePart] = utcString.split(" ");
    
    // Split the time into hours, minutes, and seconds
    let [hours, minutes] = timePart.split(":");
    
    // Convert hours to 12-hour format and determine AM/PM
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12; // Convert "0" hour to "12" for 12-hour format
    
    // Format hours and minutes with padding if needed
    hours = String(hours).padStart(2, '0');
    
    return `${datePart} at ${hours}:${minutes} ${ampm}`;
}