import * as actionTypes from '../actions/actionTypes';
import { updateObject, mergeObjects } from '../../utility/utility';

const intialState = {
    lanesListLoading: true,
    lanesListError: false,
    lanesList: [],
    lanesListMeta: {},
    laneFilterValues:{
        transportModes :  [],
        originDestinations:   [],
        productNames: []
    },
    laneDetailsLoading: false,
    laneDetailsError: false,
    laneDetails: {stability: [], routes:[]},
    laneStabilityLoading: false,
    laneStabilityError: false,
    laneStability: [],
    routeDetailsLoading: false,
    routeDetailsError: false,
    routeDetails: {},
    routeStabilityLoading: false,
    routeStabilityError: false,
    routeStability: [],
    legStabilityLoading: false,
    legStabilityError: false,
    legStability: [],
    laneInsights: {},
    laneInsightsLoading: false,
    routeInsights: {},
    routeInsightsLoading: false,
    legInsights: {},
    legInsightsLoading: false,
    insightsMapping: {},
    laneDynamicInsights: {},
    originsLoading: false,
    origins: [],
    destinationsLoading: false,
    destinations: [],
    routeEventInsightLoad: false,
    routeEventInsightData: [],
    eventListData: [],
    laneEventListData: [],
    laneHighEventListData: [],
    laneLowEventListData: [],
    laneMediumEventListData: [],
    eventCounts:null,
    allEventCounts: null,
    isEventListLoad: false,
    eventMetaTotalCount: {},
    laneEventMetaTotalCount: 0,
    isEventDetailLoad: false,
    eventDetailData: [],
    legEventInsightLoading: false,
    legEventInsightError: false,
    severityLevel: "all",
    eventTypeValue : "",
    isTransitListLoad: false,
    isTransitDataList: [],
    isTransitListMetaCurrentPage: 0,
    isTransitListMetaTotalCount: {},
    isNewPerformanceLoad: false,
    athenaInsightsDetailsSuccess: true,
    athenInsightsData: null,
    athenInsightsDataShort: null,
    newPerformanceData:   {},
    isNewLaneDetailLoad: false,
    newLaneDetailseData: {},
    isNewTemperatureLoad: false,
    newTemperatureData:  {},
    isNewStabilityLoad: false,
    athenaInsightsLoading: false,
    newStabilityData: {},
    isNewWaypointOrSegmentLoad: false,
    newWaypointOrSegmentData:   {},
    isNewRouteLoad: false,
    newRouteData:  {},
    newRouteListPopupData : {},
    lanesCount:{},
    isNewShipmentTimeLoad: false,
    newShipmentTimData: {},
    isNewComparisonLoad: false,
    newComparisonData: {},
    newEventStabilityRoutesData: [],
    newEventStabilityLanesData: [],
    newEventStabilityLegsData: [],
    isNewEventCountLoad: false,
    newEventCountData: {},
    isNewEventStabilityLoad: false,
    isDevicePerforrmanceLoad: false,
    newDevicePerformanceData: {},
    co2NewEmissionsLoading: false,
    co2NewEmissions: {},
    co2NewEmissionsError: false,
    isShipmentDetailsLoading:false, 
    shipmentDetailsRoutesData:[],
    shipmentDetailsLegsData:[],
    shipmentDetailsLanesData:[],
    shipmentsCounts:{},
    shipmentDetailsMetaData:{},
    athennaUnloadData: false,
    athenaRouteListInsightsDataShort: null,
    athenaRouteListInsightsDataShortArray: [],
    finalShipmentData:[],
};
let stability = { lane: 0, route: 0, leg: 0,};

let eventInsight = { lane: 0, route: 0, leg: 0,};


const getLanesListStart = state => {
    return updateObject(state, {
        lanesListLoading: true,
        lanesListError: false,
        //laneDetails: {stability: [], routes:[]},
    });
};
const getLanesListSuccess = (state, action) => {
    return updateObject(state, {
        lanesListLoading: false,
        lanesList: action.data.data,
        lanesListMeta: action.data.meta,
        laneFilterValues:{
            transportModes :  action.data.transport_modes,
            originDestinations:    action.data.origin_destinations,
            productNames: action.data.product_names
        },
        lanesCount: action.data.lane_counts
        }
    );
};
const getLanesListFailed = state => {
    return updateObject(state, {
        lanesListLoading: false,
        lanesListError: true,
    });
};

const getLaneDetailsStart = state => {
    return updateObject(state, {
        laneDetailsLoading: true,
        laneDetailsError: false,
        //laneDetails: {stability: [], routes:[]},
    });
};
const getLaneDetailsSuccess = (state, action) => {
    //console.log('--------------lane details: ', action);
    //console.log('1: ', {...state.laneDetails, ...action.data}.routes.map(r=>r.id));
    const lanesList = [...state.lanesList];
    const laneIndex = lanesList.findIndex(l=>l.id === action.data.id);
    if(action.updateToList) {
        const newLaneDetails = {...action.data};

        newLaneDetails.routes.map((route, rIndex) => {
            route.legs.map((leg, lIndex) => {
                leg.lane_name = newLaneDetails.lane_name;
                if(leg.leg_name && leg.leg_name.indexOf('Origin')>=0 && newLaneDetails.origin) {
                    leg.origin = newLaneDetails.origin;
                    leg.leg_name = leg.leg_name.replace('Origin', newLaneDetails.origin);
                }
                if (leg.leg_name && leg.leg_name.indexOf('Destination')>=0 && newLaneDetails.destination) {
                    leg.destination = newLaneDetails.destination;
                    leg.leg_name = leg.leg_name.replace('Destination', newLaneDetails.destination);
                }
            });
        });
        lanesList[laneIndex].lane_details = newLaneDetails;
        
        return updateObject(state, {
            laneDetailsLoading: false,
            lanesList,
        });
    } else {
        /*order legs*/
        const LaneDetails = {...state.laneDetails, ...action.data};
        let RouteDetails = state.routeDetails?.id ? {...state.routeDetails} : {...LaneDetails.routes[0]};
        LaneDetails.routes.map((route, rIndex) => {
            route.legs.map((leg, lIndex) => {
                leg.lane_name = LaneDetails.lane_name;
                if(leg.leg_name && leg.leg_name.indexOf('Origin')>=0 && LaneDetails.origin) {
                    leg.origin = LaneDetails.origin;
                    leg.leg_name = leg.leg_name.replace('Origin', LaneDetails.origin);
                }
                if (leg.leg_name && leg.leg_name.indexOf('Destination')>=0 && LaneDetails.destination) {
                    leg.destination = LaneDetails.destination;
                    leg.leg_name = leg.leg_name.replace('Destination', LaneDetails.destination);
                }
            });
        });

        RouteDetails = LaneDetails.routes[0];

        if(laneIndex>=0 && lanesList[laneIndex]){
            lanesList[laneIndex].lane_details = LaneDetails;
        }
        
        return updateObject(state, {
            laneDetailsLoading: false,
            lanesList,
            laneDetails: {...LaneDetails},
            routeDetails: RouteDetails,
        });
    }
};
const getLaneDetailsCompleted = state => {
    return updateObject(state, {
        laneDetailsLoading: false,
        laneDetailsError: false,
    });
};
const getLaneDetailsFailed = state => {
    return updateObject(state, {
        laneDetailsLoading: false,
        laneDetailsError: true,
    });
};

const getLaneStabilityStart = state => {
    stability.lane++;
    return updateObject(state, {
        laneStabilityLoading: true,
        laneStabilityError: false,
    });
};
const getLaneStabilitySuccess = (state, action) => {
    stability.lane--;
    //console.log('--------> adding lane stability---------> ', action, {...state.laneDetails, stability: action.data});
    //console.log('2: ', {...state.laneDetails, ...action.data}.routes.map(r=>r.id));
    // const {laneDetails} = state;
    // if(laneDetails.endDate){}
    // else {
    //     laneDetails.endDate = action.data[action.data.length-1]?.timestamp;
    // }
    // if(laneDetails.startDate){}
    // else {
    //     laneDetails.startDate = action.data[0]?.timestamp;
    // }
    return updateObject(state, {
        laneStabilityLoading: stability.lane===0?false:true,
        laneStability: action.data,
    });
};
const getLaneStabilityFailed = state => {
    return updateObject(state, {
        laneStabilityLoading: false,
        laneStabilityError: true,
    });
};

const getLaneInsightsStart = state => {
    return updateObject(state, {
        laneInsightsLoading: true,
        laneInsightsError: false,
    });
};
const getLaneInsightsSuccess = (state, action) => {
    const LaneInsights = {...state.laneInsights};
    if(action.del) {
        delete(LaneInsights[action.id]);
    } else {
        LaneInsights[action.id] = action.data.insights;

        let laneInsights = mergeObjects(LaneInsights[action.id].overview, LaneInsights[action.id]['in-transit_delays']);
        const tempInsights = mergeObjects(LaneInsights[action.id]['temperature_risk'], LaneInsights[action.id]['excursion_duration']);
        LaneInsights[action.id] = {...LaneInsights[action.id], overview: mergeObjects(laneInsights, tempInsights)};
    }
    console.log('LaneInsights----reducer----------> ', LaneInsights);

    return updateObject(state, {
        laneInsightsLoading: false,
        laneInsightsError: false,
        laneInsights: LaneInsights,
        insightsMapping: action.data.insights_mapping || state.insightsMapping,
        laneDynamicInsights: action.data.insights?.dynamic_insights || state.laneDynamicInsights,
    });
};
const getLaneInsightsFailed = state => {
    return updateObject(state, {
        laneInsightsLoading: false,
        laneInsightsError: true,
    });
};

const getRouteDetailsStart = state => {
    return updateObject(state, {
        routeDetailsLoading: true,
        routeDetailsError: false,
    });
};
const getRouteDetailsSuccess = (state, action) => {
    let {laneDetails, routeDetails} = state;
    let routeIndex = laneDetails.routes.findIndex(r => Number(r.id) === Number(action.id));
    routeDetails = {...laneDetails.routes[routeIndex]};
    routeIndex = routeIndex >=0 ? routeIndex : 0;
    // console.log('-------------------');
    //console.log('route Index:........ ', routeIndex, ' | routes: ', laneDetails.routes, ' | action: ... ', action);
    // console.log('-------------------');
    laneDetails.routes[routeIndex] = { ...laneDetails.routes[routeIndex], ...action.data};
    routeDetails = {...state.routeDetails, ...action.data};
    
    return updateObject(state, {
        routeDetailsLoading: false,
        laneDetails,
        routeDetails,
    });
};
const getRouteDetailsFailed = state => {
    return updateObject(state, {
        routeDetailsLoading: false,
        routeDetailsError: true,
    });
};

const getRouteStabilityStart = state => {
    stability.route++;
    return updateObject(state, {
        routeStabilityLoading: true,
        routeStabilityError: false,
    });
};
const getRouteStabilitySuccess = (state, action) => {
    stability.route--;
    // let {laneDetails, routeDetails} = state;
    // const routeIndex = laneDetails.routes.findIndex(r => Number(r.id) === Number(action.id));
    // laneDetails.routes[routeIndex].stability = action.data;
    // routeDetails = {...laneDetails.routes[routeIndex]};
    // routeDetails.stability = action.data;
    // if(laneDetails.routes[routeIndex].endDate){}
    // else {
    //     laneDetails.routes[routeIndex].endDate = action.data[action.data.length-1].timestamp;
    //     routeDetails.endDate = laneDetails.routes[routeIndex].endDate;
    // }
    // if(laneDetails.routes[routeIndex].startDate){}
    // else {
    //     laneDetails.routes[routeIndex].startDate = action.data[0].timestamp;
    //     routeDetails.startDate = laneDetails.routes[routeIndex].startDate;
    // }
    //console.log('----------------action---> route stability');
    //console.log('4: ', laneDetails.routes.map(r=>r.id));
    return updateObject(state, {
        // oldlogic
        // routeStabilityLoading: stability.route===0?false:true,
        routeStabilityLoading: false,
        routeStability: action.data,
        // laneDetails,
        // routeDetails,
    });
};
const getRouteStabilityFailed = state => {
    return updateObject(state, {
        routeStabilityLoading: false,
        routeStabilityError: true,
    });
};
const getRouteInsightsStart = state => {
    return updateObject(state, {
        routeInsightsLoading: true,
        routeInsightsError: false,
    });
};
const getRouteInsightsSuccess = (state, action) => {
    const RouteInsights = {...state.routeInsights};
    if(action.del) {
        delete(RouteInsights[action.id]);
    } else {
        RouteInsights[action.id] = action.data.insights;
        delete(RouteInsights[action.id].dynamic_insights);;
    }
    // if(action.del) {
    //     delete(RouteInsights[action.id]);
    // } else {
    //     const keys = Object.keys(RouteInsights);
    //     keys.forEach((key, i) => {
    //         if(action.id !== key) {
    //             delete(RouteInsights[key]);
    //         }
    //     });
    //     RouteInsights[action.id] = action.data;
    // }
    console.log('RouteInsights----reducer----------> ', RouteInsights);
    return updateObject(state, {
        routeInsightsLoading: false,
        routeInsightsError: false,
        routeInsights: RouteInsights,
        insightsMapping: action.data.insights_mapping || state.insightsMapping,
    });
};
const getRouteInsightsFailed = state => {
    return updateObject(state, {
        routeInsightsLoading: false,
        routeInsightsError: true,
    });
};

const getLegStabilityStart = state => {
    stability.leg++;
    return updateObject(state, {
        legStabilityLoading: true,
        legStabilityError: false,
    });
};
const getLegStabilitySuccess = (state, action) => {
    stability.leg--;

//     const {laneDetails, routeDetails} = state;
//     let routeIndex = laneDetails.routes.findIndex(r => Number(r.id) === Number(routeDetails.id));
//     let legIndex = laneDetails.routes[routeIndex].legs.findIndex(l => Number(l.id) === Number(action.id));
//     console.log('-------->>>>action.id--->>>> ', action.id);  
//     if(laneDetails.routes[routeIndex].legs[legIndex]) {
//         laneDetails.routes[routeIndex].legs[legIndex].stability = action.data;
//         routeDetails.legs[legIndex].stability = action.data;
//     }
// console.log('-reducer----> ', routeDetails, '\n',laneDetails);
    //console.log('5: ', laneDetails.routes.map(r=>r.id));
    return updateObject(state, {
        legStabilityLoading: stability.leg===0?false:true,
        // laneDetails,
        // routeDetails,
    });
};
const getLegStabilityFailed = state => {
    return updateObject(state, {
        legStabilityLoading: false,
        legStabilityError: true,
    });
};

const getLegInsightsStart = state => {
    return updateObject(state, {
        legInsightsLoading: true,
        legInsightsError: false,
    });
};
const getLegInsightsSuccess = (state, action) => {
    const LegInsights = {...state.legInsights};
    // const selectedLegIds = action.selectedLegs.map(leg=>leg.uid);
    // Object.keys(LegInsights).forEach(key => {
    //     if(selectedLegIds.includes(key)){}
    //     else{
    //         delete(LegInsights[key]);
    //     }
    // });
    if(action.del) {
        delete(LegInsights[action.id]);
    } else {
        LegInsights[action.id] = action.data.insights;
        delete(LegInsights[action.id].dynamic_insights);
    }
    //console.log('============reducer leg insights=====>>>>>>>>>>>>>>');
    return updateObject(state, {
        legInsightsLoading: false,
        legInsightsError: false,
        legInsights: LegInsights,
        insightsMapping: action.data.insights_mapping || state.insightsMapping,
    });
};
const getLegInsightsFailed = state => {
    return updateObject(state, {
        legInsightsLoading: false,
        legInsightsError: true,
    });
};

const getOriginsStart = state => {
    return updateObject(state, {
        originsLoading: true,
    });
};
const getOriginsSuccess = (state, action) => {
    return updateObject(state, {
        originsLoading: false,
        origins: action.data.data.sort(),
    });
};
const getOriginsFailed = state => {
    return updateObject(state, {
        originsLoading: false,
        origins: []
    });
};
const getDestinationsStart = state => {
    return updateObject(state, {
        destinationsLoading: true,
    });
};
const getDestinationsSuccess = (state, action) => {
    return updateObject(state, {
        destinationsLoading: false,
        destinations: action.data.data.sort(),
    });
};
const getDestinationsFailed = state => {
    return updateObject(state, {
        destinationsLoading: false,
        destinations: []
    });
};
const getLaneIdStart = state => {
    return updateObject(state, {
        laneIdLoading: true,
    });
};
const getLaneIdSuccess = (state, action) => {
    return updateObject(state, {
        laneIdLoading: false,
    });
};


const  getRouteEventFailed = state => {
    return updateObject(state, {
        routeEventInsightLoad: false,
        routeEventInsightData: []
    });
};

const getRouteEventStart = state => {
    return updateObject(state, {
        routeEventInsightLoad: false,
        routeEventInsightData: [],
    });
};

const  getRouteEventSuccess = (state, action) => {
    return updateObject(state, {
        routeEventInsightLoad: false,
        routeEventInsightData: [...state.routeEventInsightData, action.data[0]],
    });
};

const  clearEventDetail = state => {
    return updateObject(state, {
        isEventDetailLoad: false,
        eventDetailData: [],
    });
}

const  getEventDetailFailed = state => {
    return updateObject(state, {
        isEventDetailLoad: false,
        eventDetailData: [],

    });
};

const getEventDetailStart = state => {
    return updateObject(state, {
        isEventDetailLoad: true,
        eventDetailData: [],
    });
};

const  getEventDetailSuccess = (state, action) => {
    return updateObject(state, {
        isEventDetailLoad: false,
        eventDetailData: [action.data[0]?.data],
    });
};

const  clearEventList = state => {
    return updateObject(state, {
        isEventListLoad: false,
        eventListData: [],
        eventMetaTotalCount: {}
    });
}

const  getEventListFailed = state => {
    return updateObject(state, {
        isEventListLoad: false,
        // laneEventListData:[],
        // laneHighEventListData: [],
        // laneLowEventListData: [],
        // laneMediumEventListData: [],

    });
};

const getEventListStart = state => {
    return updateObject(state, {
        isEventListLoad: true,
        // laneEventListData:[],
        // laneHighEventListData: [],
        // laneLowEventListData: [],
        // laneMediumEventListData: [],
        laneEventMetaTotalCount: 0,
        eventCounts: null,
    });
};

const  getEventListSuccess = (state, action) => {
    return updateObject(state, {
        isEventListLoad: false,
        eventMetaTotalCount: action.data[0].meta,
        laneEventMetaTotalCount: action.data[0].meta.current_page,
        eventCounts:action.data[0].EventCounts,
        allEventCounts: 
         action?.allDetails?.laneListPage  && (action?.allDetails?.risk_level === "") ? 
        action.data[0].EventCounts : state.allEventCounts,
        laneEventListData:   
        action?.allDetails?.laneListPage  && (action?.allDetails?.risk_level === "") 
        ? 
        action.page === 1 
        ?
            [...action.data[0]?.data]
        : 
            [...state.laneEventListData, ...action.data[0]?.data] :  
        [...state.laneEventListData],
        laneHighEventListData: action?.allDetails?.laneListPage && action?.allDetails?.risk_level === "high" 
        ? 

            action.page === 1 
            ?
                [...action.data[0]?.data] 
            : 
                [...state.laneHighEventListData, ...action.data[0]?.data] 
        :  
            [...state.laneHighEventListData],

        laneLowEventListData: action?.allDetails?.laneListPage && 
        action?.allDetails?.risk_level === "low" 
        ? 
            action.page === 1 
            ?
                [...action.data[0]?.data]
            : 
                [...state.laneLowEventListData, ...action.data[0]?.data] 
        :  
            [...state.laneLowEventListData],
        laneMediumEventListData: action?.allDetails?.laneListPage && 
        action?.allDetails?.risk_level === "medium" ? 
            action.page === 1 
            ?
                [...action.data[0]?.data]
            : 
                [...state.laneMediumEventListData, ...action.data[0]?.data] 
        :  
            [...state.laneMediumEventListData],
        eventListData: action.page === 1 ?  [...action.data[0]?.data]  : [...state.eventListData, ...action.data[0]?.data],
    });
};



const  getLaneTransitListStart = state => {
    return updateObject(state, {
        isTransitListLoad: true,
    });
};


const getLaneTransitListFailed = state => {
    return updateObject(state, {
        isTransitListLoad: false,
    });
};

const getLaneTransitListSuccess = (state, action) => {
    return updateObject(state, {
        isTransitListLoad: false,
        isTransitListMetaTotalCount: action.data[0].meta,
        isTransitListMetaCurrentPage: action.data[0].meta.current_page,
        isTransitDataList: action.data[0].meta.current_page === 1 ?  action?.data[0]?.data :
        [...state.isTransitDataList, ...action?.data[0]?.data]
    });
};



// ---

const  getNewPerformanceStart = state => {
    return updateObject(state, {
        isNewPerformanceLoad: true,
        newPerformanceData : {}
    });
};


const getNewPerformanceFailed = state => {
    return updateObject(state, {
        isNewPerformanceLoad: false,
        newPerformanceData : {}
    });
};

const getNewPerformanceSuccess = (state, action) => {
    return updateObject(state, {
        isNewPerformanceLoad: false,
        newPerformanceData:   action.data
    });
};


const  getNewLaneDetailsStart = state => {
    return updateObject(state, {
        isNewLaneDetailLoad: true,
    });
};


const getNewLaneDetailsFailed = state => {
    return updateObject(state, {
        isNewLaneDetailLoad: false,
    });
};

const getNewLaneDetailsSuccess = (state, action) => {
    return updateObject(state, {
        isNewLaneDetailLoad: false,
        newLaneDetailseData:   action.data
    });
};

const  getNewTemperatureStart = state => {
    return updateObject(state, {
        isNewTemperatureLoad: true,
        newTemperatureData: {}
    });
};


const getNewTemperatureFailed = state => {
    return updateObject(state, {
        isNewTemperatureLoad: false,
        newTemperatureData: {}
    });
};

const getNewTemperatureSuccess = (state, action) => {
    return updateObject(state, {
        isNewTemperatureLoad: false,
        newTemperatureData:  action.data
    });
};


const  getNewStabilityStart = state => {
    return updateObject(state, {
        isNewStabilityLoad: true,
    });
};


const getNewStabilityFailed = state => {
    return updateObject(state, {
        isNewStabilityLoad: false,
    });
};

const getNewStabilitySuccess = (state, action) => {
    return updateObject(state, {
        isNewStabilityLoad: false,
        newStabilityData:   action.data
    });
};


const  getNewShipmentTimeStart = state => {
    return updateObject(state, {
        isNewShipmentTimeLoad: true,
        newShipmentTimData: {}
    });
};


const getNewShipmentTimeFailed = state => {
    return updateObject(state, {
        isNewShipmentTimeLoad: false,
        newShipmentTimData: {}
    });
};

const getNewShipmentTimeSuccess = (state, action) => {
    return updateObject(state, {
        isNewShipmentTimeLoad: false,
        newShipmentTimData:   action.data
    });
};

const  getNewComparisonStart = state => {
    return updateObject(state, {
        isNewComparisonLoad: true,
        newComparisonData: {}
    });
};


const getNewComparisonFailed = state => {
    return updateObject(state, {
        isNewComparisonLoad: false,
        newComparisonData: {}
    });
};

const getNewComparisonSuccess = (state, action) => {
    return updateObject(state, {
        isNewComparisonLoad: false,
        newComparisonData:   action.data
    });
};




const  getNewRouteStart = state => {
    return updateObject(state, {
        isNewRouteLoad: true,
    });
};


const getNewRouteFailed = state => {
    return updateObject(state, {
        isNewRouteLoad: false,
    });
};

const getNewRouteSuccess = (state, action) => {
    if(action.flag) {
        return updateObject(state, {
            isNewRouteLoad: false,
            newRouteListPopupData:   action.data
        });

    } else {
        return updateObject(state, {
            isNewRouteLoad: false,
            newRouteData:   action.data
        });
    }
   
};

const  getNewEventStabilityStart = state => {
    return updateObject(state, {
        isNewEventStabilityLoad: true,
    });
};
const getNewEventStabilityFailed = state => {
    return updateObject(state, {
        isNewEventStabilityLoad: false,
    });
};
const getNewEventStabilitySuccess = (state, action) => {

    if(action.data.route==='routes'){
        return updateObject(state, {
            isNewEventStabilityLoad: false,
            newEventStabilityRoutesData: action?.data?.data
        });
    }else if(action.data.route==='legs'){
        return updateObject(state, {
            isNewEventStabilityLoad: false,
            newEventStabilityLegsData: action?.data?.data
        });
    }else if(action.data.route==='lanes'){
        return updateObject(state, {
            isNewEventStabilityLoad: false,
            newEventStabilityLanesData: action?.data?.data
        });
    }
};
const  getShipmentDetailsStart = state => {
    return updateObject(state, {
        isShipmentDetailsLoading: true,
    });
};

const getShipmentDetailsFailed = state => {
    return updateObject(state, {
        isShipmentDetailsLoading: false,
    });
};

const clearFinalShipmentData = (state, _) => {
    return updateObject(state, {finalShipmentData:[]})
   
}

const getShipmentDetailsSuccess = (state, action) => {

    if(action.data.mode==='routes'){
        return updateObject(state, {
            isShipmentDetailsLoading: false,
            shipmentDetailsRoutesData: action?.data?.data,
            shipmentsCounts: action?.data?.shipmentsCounts,
            shipmentDetailsMetaData: action?.data?.meta
        });
    }else if(action.data.mode==='legs'){
        return updateObject(state, {
            isShipmentDetailsLoading: false,
            shipmentDetailsLegsData: action?.data?.data,
            shipmentsCounts: action?.data?.shipmentsCounts,
            shipmentDetailsMetaData: action?.data?.meta
        });
    }else if(action.data.mode==='lanes'){
        if(action.data.flagValue) {
            return updateObject(state, {
                finalShipmentData:[...state.finalShipmentData, ...action?.data?.data],
                isShipmentDetailsLoading: false,
                shipmentDetailsLanesData: action?.data?.data,
                shipmentsCounts: action?.data?.shipmentsCounts,
                shipmentDetailsMetaData: action?.data?.meta
            });
        } else {
            return updateObject(state, {
                isShipmentDetailsLoading: false,
                shipmentDetailsLanesData: action?.data?.data,
                shipmentsCounts: action?.data?.shipmentsCounts,
                shipmentDetailsMetaData: action?.data?.meta
            });
        }
      
    }
};


const  getNewEventCountStart = state => {
    return updateObject(state, {
        isNewEventCountLoad: true,
        newEventCountData :{}
    });
};
const getNewEventCountFailed = state => {
    return updateObject(state, {
        isNewEventCountLoad: false,
        newEventCountData : {}
    });
};
const getNewEventCountSuccess = (state, action) => {
    return updateObject(state, {
        isNewEventCountLoad: false,
        newEventCountData: action.data
    });
};

const  getDevicePerformanceStart = state => {
    return updateObject(state, {
        isDevicePerforrmanceLoad: true,
        newDevicePerformanceData : {}
    });
};
const getDevicePerformanceFailed = state => {
    return updateObject(state, {
        isDevicePerforrmanceLoad: false,
        newDevicePerformanceData: {}
    });
};
const getDevicePerformanceSuccess = (state, action) => {
    return updateObject(state, {
        isDevicePerforrmanceLoad: false,
        newDevicePerformanceData: action.data
    });
};


// ---


const getLegEventInsigthStart = state => {
    eventInsight.leg++;
    return updateObject(state, {
        legEventInsightLoading: true,
        legEventInsightError: false,
    });
};

const getLegEventInsigthSuccess = (state, action) => {
    eventInsight.leg--;

    return updateObject(state, {
        legStabilityLoading: eventInsight.leg===0?false:true,
    });
};
const getLegEventInsigthFailed = state => {
    return updateObject(state, {
        legEventInsightLoading: false,
        legEventInsightError: true,
    });
};

const getSeverityLevel = (state, action) => {
    return updateObject(state, {
        severityLevel: action.level,
    });
};

const getEventTypeMap = (state, action) => {
    return updateObject(state, {
        eventTypeValue: action.eventTypeValue,
    });
};


const getNewCO2EmissionsStart = state => {
    return updateObject(state, {
        co2NewEmissionsLoading: true,
        co2NewEmissionsError: false,
    });
};
const getNewCO2EmissionsSuccess = (state, action) => {
    const data = {...action.data};
    
    //console.log('============reducer leg insights=====>>>>>>>>>>>>>>');
    return updateObject(state, {
        co2NewEmissionsLoading: false,
        co2NewEmissionsError: false,
        co2NewEmissions: data,
    });
};
const getNewCO2EmissionsFailed = state => {
    return updateObject(state, {
        co2NewEmissionsLoading: false,
        co2NewEmissionsError: true,
    });
};


const getAthenaInsightStart = (state, action) => {
    if(action.format === "long" ) {
        return updateObject(state, {
            athenaInsightsLoading: true,
            athenaInsightsDetailsSuccess: true,
            athenInsightsData: null,
            athennaUnloadData : false,
        });
    } else {
        if(action.pageName === 'routeList'){
            return updateObject(state,{
                athenaInsightsLoading: true,
                athenaRouteListInsightsDataShort: null,
                // athennaUnloadData : false,
                // athenaRouteListInsightsDataShortArray: [],
                athenaInsightsDetailsSuccess: true
                })
        }
        return updateObject(state, {
            athenaInsightsLoading: true,
            athennaUnloadData : false,
            athenaInsightsDetailsSuccess: true,
            athenInsightsDataShort: null

        });

    }
}
const getAthenaInsightSuccess = (state, action) => {
    if(action.format === "long" ) {
        return updateObject(state, {
            athennaUnloadData: false,
            athenaInsightsLoading: false,
            athenInsightsData: action.data,
            athenaInsightsDetailsSuccess: true,
        });
    } else {
        if(action.pageName === 'routeList'){
            return updateObject(state,{
            athenaInsightsLoading: false,
            athenaRouteListInsightsDataShortArray :  
            [...state.athenaRouteListInsightsDataShortArray,
                {
                    routeId:action.idValue,
                    athennaData: action.data,
                    athennaUnloadData: false,

                }
             ]
            ,
            // athenaRouteListInsightsDataShort: action.data,
            athenaInsightsDetailsSuccess: true
            })
        }
        return updateObject(state, {
            athenaInsightsLoading: false,
            athennaUnloadData: false,
            athenInsightsDataShort: action.data,
            athenaInsightsDetailsSuccess: true,
        });

    }
 
}


const getAthenaInsightErrorHandling = (state, action) => {
    if(action.format === "long" ) {
        return updateObject(state, {
            athenaInsightsLoading: false,
            athenInsightsData: null,
            athenaInsightsDetailsSuccess: true,
            athennaUnloadData : true
        });
    } else {
        if(action.pageName === 'routeList'){
            return updateObject(state,{
            athenaInsightsLoading: false,
            athenaRouteListInsightsDataShortArray :  
            [...state.athenaRouteListInsightsDataShortArray,
                {
                    routeId:action.idValue,
                    athennaData: null,
                    athennaUnloadData: true
                }
             ]
            ,
            athenaRouteListInsightsDataShort: null,
            athenaInsightsDetailsSuccess: true
            })
        }
        return updateObject(state, {
            athenaInsightsLoading: false,
            athenInsightsDataShort: null,
            athennaUnloadData: true,
            athenaInsightsDetailsSuccess: true,
        });

    }
 
}



const getAthenaInsightFailed = state => {

        return updateObject(state, {
            athenaInsightsLoading: false,
            athenaInsightsDetailsSuccess: false,
            athenInsightsData: null,
            athennaUnloadData: false,
            athenInsightsDataShort:  null,
            athenaRouteListInsightsDataShort: null,
            // athenaRouteListInsightsDataShortArray: []

        });

}



const riskIQ = (state = intialState, action) => {
    switch (action.type) {
        case actionTypes.GET_LANES_LIST_START:
            return getLanesListStart(state, action);
        case actionTypes.GET_LANES_LIST_SUCCESS:
            return getLanesListSuccess(state, action);
        case actionTypes.GET_LANES_LIST_FAILED:
            return getLanesListFailed(state, action);
        case actionTypes.GET_LANE_DETAILS_START:
            return getLaneDetailsStart(state, action);
        case actionTypes.GET_LANE_DETAILS_SUCCESS:
            return getLaneDetailsSuccess(state, action);
        case actionTypes.GET_LANE_DETAILS_COMPLETED:
            return getLaneDetailsCompleted(state, action);
        case actionTypes.GET_LANE_DETAILS_FAILED:
            return getLaneDetailsFailed(state, action);
        case actionTypes.GET_LANE_STABILITY_START:
            return getLaneStabilityStart(state, action);
        case actionTypes.GET_LANE_STABILITY_SUCCESS:
            return getLaneStabilitySuccess(state, action);
        case actionTypes.GET_LANE_STABILITY_FAILED:
            return getLaneStabilityFailed(state, action);
        case actionTypes.GET_LANE_INSIGHTS_START:
            return getLaneInsightsStart(state, action);
        case actionTypes.GET_LANE_INSIGHTS_SUCCESS:
            return getLaneInsightsSuccess(state, action);
        case actionTypes.GET_LANE_INSIGHTS_FAILED:
            return getLaneInsightsFailed(state, action);
        case actionTypes.GET_ROUTE_DETAILS_START:
            return getRouteDetailsStart(state, action);
        case actionTypes.GET_ROUTE_DETAILS_SUCCESS:
            return getRouteDetailsSuccess(state, action);
        case actionTypes.GET_ROUTE_DETAILS_FAILED:
            return getRouteDetailsFailed(state, action);
        case actionTypes.GET_ROUTE_STABILITY_START:
            return getRouteStabilityStart(state, action);
        case actionTypes.GET_ROUTE_STABILITY_SUCCESS:
            return getRouteStabilitySuccess(state, action);
        case actionTypes.GET_ROUTE_STABILITY_FAILED:
            return getRouteStabilityFailed(state, action);
        case actionTypes.GET_ROUTE_INSIGHTS_START:
            return getRouteInsightsStart(state, action);
        case actionTypes.GET_ROUTE_INSIGHTS_SUCCESS:
            return getRouteInsightsSuccess(state, action);
        case actionTypes.GET_ROUTE_INSIGHTS_FAILED:
            return getRouteInsightsFailed(state, action);
        case actionTypes.GET_LEG_STABILITY_START:
            return getLegStabilityStart(state, action);
        case actionTypes.GET_LEG_STABILITY_SUCCESS:
            return getLegStabilitySuccess(state, action);
        case actionTypes.GET_LEG_STABILITY_FAILED:
            return getLegStabilityFailed(state, action);
        case actionTypes.GET_LEG_INSIGHTS_START:
            return getLegInsightsStart(state, action);
        case actionTypes.GET_LEG_INSIGHTS_SUCCESS:
            return getLegInsightsSuccess(state, action);
        case actionTypes.GET_LEG_INSIGHTS_FAILED:
            return getLegInsightsFailed(state, action);
        case actionTypes.GET_ORIGINS_START:
            return getOriginsStart(state, action);
        case actionTypes.GET_ORIGINS_SUCCESS:
            return getOriginsSuccess(state, action);
        case actionTypes.GET_ORIGINS_FAILED:
            return getOriginsFailed(state, action);
        case actionTypes.GET_DESTINATIONS_START:
            return getDestinationsStart(state, action);
        case actionTypes.GET_DESTINATIONS_SUCCESS:
            return getDestinationsSuccess(state, action);
        case actionTypes.GET_DESTINATIONS_FAILED:
            return getDestinationsFailed(state, action);
        case actionTypes.GET_LANE_ID_START:
            return getLaneIdStart(state, action);
        case actionTypes.GET_LANE_ID_SUCCESS:
            return getLaneIdSuccess(state, action);
        case actionTypes.GET_ROUTE_EVENT_INSIGTHS_START:
            return getRouteEventStart(state, action);
        case actionTypes.GET_ROUTE_EVENT_INSIGTHS_SUCCESS:
            return getRouteEventSuccess(state, action);
        case actionTypes.GET_ROUTE_EVENT_INSIGTHS_FAILED:
            return getRouteEventFailed(state, action);

        case actionTypes.GET_EVENT_LIST_START:
            return getEventListStart(state, action);
        case actionTypes.GET_EVENT_LIST_SUCCESS:
            return getEventListSuccess(state, action);
        case actionTypes.GET_EVENT_LIST_FAILED:
            return getEventListFailed(state, action);
        case actionTypes.EVENT_LIST_CLEAR:
            return clearEventList(state, action);

        
        case actionTypes.EVENT_DETAILS_START:
            return getEventDetailStart(state, action);
        case actionTypes.EVENT_DETAILS_SUCCESS:
            return getEventDetailSuccess(state, action);
        case actionTypes.EVENT_DETAILS_FAILED:
            return getEventDetailFailed(state, action);
        case actionTypes.EVENT_DETAILS_CLEAR:
            return clearEventDetail(state, action);
        case actionTypes.GET_LEG_EVENT_INSIGTHS_START:
            return getLegEventInsigthStart(state, action);
        case actionTypes.GET_LEG_EVENT_INSIGTHS_SUCCESS:
            return getLegEventInsigthSuccess(state, action);
        case actionTypes.GET_LEG_EVENT_INSIGTHS_FAILED:
            return getLegEventInsigthFailed(state, action);
        case actionTypes.GET_LANE_TRANSIT_LIST_START:
            return getLaneTransitListStart(state, action);
        case actionTypes.GET_LANE_TRANSIT_LIST_SUCCESS:
            return getLaneTransitListSuccess(state, action);
        case actionTypes.GET_LANE_TRANSIT_LIST_FAILED:
            return getLaneTransitListFailed(state, action);  
        case actionTypes.GET_NEW_PERFORMANCE_START:
            return getNewPerformanceStart(state, action);
        case actionTypes.GET_NEW_PERFORMANCE_SUCCESS:
            return getNewPerformanceSuccess(state, action);
        case actionTypes.GET_NEW_PERFORMANCE_FAILED:
            return getNewPerformanceFailed(state, action);  
        case actionTypes.GET_NEW_LANE_DETAILS_START:
            return getNewLaneDetailsStart(state, action);
        case actionTypes.GET_NEW_LANE_DETAILS_SUCCESS:
            return getNewLaneDetailsSuccess(state, action);
        case actionTypes.GET_NEW_LANE_DETAILS_FAILED:
            return getNewLaneDetailsFailed(state, action);  
        case actionTypes.GET_NEW_TEMPERATURE_START:
            return getNewTemperatureStart(state, action);
        case actionTypes.GET_NEW_TEMPERATURE_SUCCESS:
            return getNewTemperatureSuccess(state, action);
        case actionTypes.GET_NEW_TEMPERATURE_FAILED:
            return getNewTemperatureFailed(state, action);  
        case actionTypes.GET_NEW_STABILITY_START:
            return getNewStabilityStart(state, action);
        case actionTypes.GET_NEW_STABILITY_SUCCESS:
            return getNewStabilitySuccess(state, action);
        case actionTypes.GET_NEW_STABILITY_FAILED:
            return getNewStabilityFailed(state, action);
            
        case actionTypes. GET_NEW_SHIPMENT_TIME_START:
            return getNewShipmentTimeStart(state, action);
        case actionTypes. GET_NEW_SHIPMENT_TIME_SUCCESS:
            return getNewShipmentTimeSuccess(state, action);
        case actionTypes. GET_NEW_SHIPMENT_TIME_FAILED:
            return getNewShipmentTimeFailed(state, action);

        case actionTypes.GET_NEW_COMPARISON_START:
            return getNewComparisonStart(state, action);
        case actionTypes.GET_NEW_COMPARISON_SUCCESS:
            return getNewComparisonSuccess(state, action);
        case actionTypes.GET_NEW_COMPARISON_FAILED:
            return getNewComparisonFailed(state, action);  

        case actionTypes.GET_NEW_ROUTE_START:
            return getNewRouteStart(state, action);
        case actionTypes.GET_NEW_ROUTE_SUCCESS:
            return getNewRouteSuccess(state, action);
        case actionTypes.GET_NEW_ROUTE_FAILED:
            return getNewRouteFailed(state, action);  

        case actionTypes.GET_NEW_EVENT_STABILITY_START:
            return getNewEventStabilityStart(state, action);
        case actionTypes.GET_NEW_EVENT_STABILITY_SUCCESS:
            return getNewEventStabilitySuccess(state, action);
        case actionTypes.GET_NEW_EVENT_STABILITY_FAILED:
            return getNewEventStabilityFailed(state, action);
        case actionTypes.GET_NEW_SHIPMENT_DETAILS_START:
            return getShipmentDetailsStart(state, action);
        case actionTypes.GET_NEW_SHIPMENT_DETAILS_FAILED:
            return getShipmentDetailsFailed(state, action);
        case actionTypes.GET_NEW_SHIPMENT_DETAILS_SUCCESS:
            return getShipmentDetailsSuccess(state, action);
        case actionTypes.CLEAR_FINAL_SHIPMENT_DATA:
            return clearFinalShipmentData(state, action);
        case actionTypes.GET_NEW_EVENT_COUNT_START:
            return getNewEventCountStart(state, action);
        case actionTypes.GET_NEW_EVENT_COUNT_SUCCESS:
            return getNewEventCountSuccess(state, action);
        case actionTypes.GET_NEW_EVENT_COUNT_FAILED:
            return getNewEventCountFailed(state, action);

        case actionTypes.GET_NEW_DEVICE_PERFPORMANCE_START:
            return getDevicePerformanceStart(state, action);
        case actionTypes.GET_NEW_DEVICE_PERFPORMANCE_SUCCESS:
            return getDevicePerformanceSuccess(state, action);
        case actionTypes.GET_NEW_DEVICE_PERFPORMANCE_FAILED:
            return getDevicePerformanceFailed(state, action);
    
        case actionTypes.EVENT_LIST_SEVERITY:
            return getSeverityLevel(state, action);

            case actionTypes.EVENT_TYPE_MAP:
                return getEventTypeMap(state, action);


        case actionTypes.GET_NEW_CO2_EMISSIONS_START:
            return getNewCO2EmissionsStart(state, action);
        case actionTypes.GET_NEW_CO2_EMISSIONS_SUCCESS:
            return getNewCO2EmissionsSuccess(state, action);
        case actionTypes.GET_NEW_CO2_EMISSIONS_FAILED:
            return getNewCO2EmissionsFailed(state, action);

        case actionTypes.GET_ATHENA_INSIGHT_START:
            return getAthenaInsightStart(state, action);
        case actionTypes.GET_ATHENA_INSIGHT_SUCCESS:
            return getAthenaInsightSuccess(state, action);
        case actionTypes.GET_ATHENA_INSIGHT_ERROR_HANDLING:
            return getAthenaInsightErrorHandling(state, action);
        case actionTypes.GET_ATHENA_INSIGHT_FAILED:
            return getAthenaInsightFailed(state, action);

        default:
            return state;
    }
};

export default riskIQ;