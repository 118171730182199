const headerLabels = ()=> {
    return {
        "lane_risk_score" : {
            "label" : "Lane Risk Assessment",
            "active_dashboard": "Lane Performance",
            "active_dashboard_link": "Active Lane Insights",
        },
        "risk_scenario_planning" : {
            "label" : "Risk Comparative Analysis"
        },
        "pdf_parser" : {
            "label" : "PDF Upload",
            "passive_dashboard": "Passive Lane Insights",
        },
        "config" : {
            "label" : "Administration",
            "sub_menu" : {
                "product_type_config" : "Product Type Configuration",
                "pdf_upload_config" : "PDF Upload Configuration",
                "users" : "Users",
                "groups" : "Groups",
                "companies" : "Companies",
                "groups_companies" : "Companies & Groups Management",
            }
        },
        "help" : {
            "label" : "Help"
        }
    }
};
export default headerLabels();