export const GET_ACTIVE_USER_START = 'GET_ACTIVE_USER_START';
export const GET_ACTIVE_USER_SUCCESS = 'GET_ACTIVE_USER_SUCCESS';
export const GET_USER_UPDATE_PROFILE_SUCCESS = 'GET_USER_UPDATE_PROFILE_SUCCESS';
export const GET_UPDATE_USER_FAILED = 'GET_UPDATE_USER_FAILED';
export const GET_ACTIVE_USER_FAILED = 'v_FAILED';
export const SEND_VERIFICATION_CODE = 'SEND_VERIFICATION_CODE';
export const ACCEPT_TERMS_SUCCESS = 'ACCEPT_TERMS_SUCCESS';
export const ACCEPT_TERMS_FAILED = 'ACCEPT_TERMS_FAILED';

export const GET_RECENT_ACTIVITY = 'RECENT_ACTIVITY';
export const GET_ACTIVE_SHIPMENTS = 'ACTIVE_SHIPMENTS';
export const GET_ACTIVE_RETURNS = 'ACTIVE_RETURNS';
export const GET_INVENTORY_DATA = 'INVENTORY_DATA';
export const GET_USER_NOT_FOUND = 'GET_USER_NOT_FOUND';
export const SEND_EMAIL_ID = 'SEND_EMAIL_ID';
export const SET_PASSWORD = 'SET_PASSWORD';
export const UPDATE_PASSWORD = 'UPDATE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const GET_REGISTER = 'GET_REGISTER';
export const GET_COMPANY_NAME = 'GET_COMPANY_NAME';

export const GET_ALL_USERS_LIST_START = 'GET_ALL_USERS_lIST_START';
export const GET_ALL_USERS_LIST_SUCCESS = 'GET_ALL_USERS_lIST_SUCCESS';
export const GET_ALL_USERS_LIST_FAILED = 'GET_ALL_USERS_lIST_FAILED';

export const CREATE_USER_START = 'CREATE_USER_START';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';

export const UPDATE_USER_START = 'UPDATE_USER_START';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const CLEAR_USER_FORM_ERRORS = 'CLEAR_USER_FORM_ERRORS';

export const MULTI_DISABLE_USERS_START = 'MULTI_DISABLE_USERS_START';
export const MULTI_DISABLE_USERS_SUCCESS = 'MULTI_DISABLE_USERS_SUCCESS';
export const MULTI_DISABLE_USERS_FAILED = 'MULTI_DISABLE_USERS_FAILED';

export const DISABLE_USER_START = 'DISABLE_USER_START';
export const DISABLE_USER_SUCCESS = 'DISABLE_USER_SUCCESS';
export const DISABLE_USER_FAILED = 'DISABLE_USER_FAILED';

export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const ENABLE_DISABLE_USER_SUCCESS = 'ENABLE_DISABLE_USER_SUCCESS';

export const VIEW_USER_DETAILS_START = 'VIEW_USER_DETAILS_START';
export const VIEW_USER_DETAILS_SUCCESS = 'VIEW_USER_DETAILS_SUCCESS';
export const VIEW_USER_DETAILS_FAILED = 'VIEW_USER_DETAILS_FAILED';

export const BULK_UPLOAD_USERS_START = 'BULK_UPLOAD_USERS_START';
export const BULK_UPLOAD_USERS_SUCCESS = 'BULK_UPLOAD_USERS_SUCCESS';
export const BULK_UPLOAD_USERS_FAILED = 'BULK_UPLOAD_USERS_FAILED';

export const USER_SHIPMENT_STATISTICS = 'USER_SHIPMENT_STATISTICS';

export const GET_COMPANIES_START = 'GET_COMPANIES_START';
export const GET_COMPANIES_SUCCESS = 'GET_COMPANIES_SUCCESS';
export const GET_COMPANIES_FAILED = 'GET_COMPANIES_FAILED';
export const GET_COMPANY_USER_LIST_START = 'GET_COMPANY_USER_LIST_START';
export const GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS';
export const GET_COMPANY_DEVICE_LIST_START = 'GET_COMPANY_DEVICE_LIST_START';
export const GET_COMPANY_DEVICES_SUCCESS = 'GET_COMPANY_DEVICES_SUCCESS';
export const GET_COMPANY_SHIPMENT_COUNT_SUCCESS = 'GET_COMPANY_SHIPMENT_COUNT_SUCCESS';

export const GET_COMPANIES_SEARCH_SUGGESTIONS_START = 'GET_COMPANIES_SEARCH_SUGGESTIONS_START';
export const GET_COMPANIES_SEARCH_SUGGESTIONS_SUCCESS = 'GET_COMPANIES_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_COMPANIES_SEARCH_SUGGESTIONS_FAILED = 'GET_COMPANIES_SEARCH_SUGGESTIONS_FAILED';
export const GET_COMPANY_DETAILS_SUCCESS = 'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_START = 'GET_COMPANY_DETAILS_START';

export const ADD_NEW_COMPANY_START = 'ADD_NEW_COMPANY_START';
export const ADD_NEW_COMPANY_SUCCESS = 'ADD_NEW_COMPANY_SUCCESS';
export const ADD_NEW_COMPANY_FAILED = 'ADD_NEW_COMPANY_FAILED';
export const CLEAR_COMPANY_FORM_ERRORS = 'CLEAR_COMPANY_FORM_ERRORS';

export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAILED = 'UPDATE_COMPANY_FAILED';

export const DELETE_COMPANY_START = 'DELETE_COMPANY_START';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';

export const GET_DEVICE_TYPES_START = 'GET_DEVICE_TYPES_START';
export const GET_DEVICE_TYPES_SUCCESS = 'GET_DEVICE_TYPES_SUCCESS';

export const GET_COMPANY_FEATURES_START = 'GET_COMPANY_FEATURES_START';
export const GET_COMPANY_FEATURES_SUCCESS = 'GET_COMPANY_FEATURES_SUCCESS';
export const GET_COMPANY_FEATURES_FAILED = 'GET_COMPANY_FEATURES_FAILED';

export const UPDATE_COMPANY_FEATURES_START = 'UPDATE_COMPANY_FEATURES_START';
export const UPDATE_COMPANY_FEATURES_SUCCESS = 'UPDATE_COMPANY_FEATURES_SUCCESS';
export const UPDATE_COMPANY_FEATURES_FAILED = 'UPDATE_COMPANY_FEATURES_FAILED';

export const GET_DEVICES_START = 'GET_DEVICES_START';
export const GET_DEVICES_SUCCESS = 'GET_DEVICES_SUCCESS';
export const GET_DEVICES_FAILED = 'GET_DEVICES_FAILED';

export const GET_DEVICES_SEARCH_SUGGESTIONS_START = 'GET_DEVICES_SEARCH_SUGGESTIONS_START';
export const GET_DEVICES_SEARCH_SUGGESTIONS_SUCCESS = 'GET_DEVICES_SEARCH_SUGGESTIONS_SUCCESS';
export const GET_DEVICES_SEARCH_SUGGESTIONS_FAILED = 'GET_DEVICES_SEARCH_SUGGESTIONS_FAILED';

export const ADD_NEW_DEVICE_START = 'ADD_NEW_DEVICE_START';
export const ADD_NEW_DEVICE_SUCCESS = 'ADD_NEW_DEVICE_SUCCESS';
export const ADD_NEW_DEVICE_FAILED = 'ADD_NEW_DEVICE_FAILED';

export const UPDATE_DEVICE_START = 'UPDATE_DEVICE_START';
export const UPDATE_DEVICE_SUCCESS = 'UPDATE_DEVICE_SUCCESS';
export const UPDATE_DEVICE_FAILED = 'UPDATE_DEVICE_FAILED';

export const DELETE_DEVICE_START = 'DELETE_DEVICE_START';
export const DELETE_DEVICE_SUCCESS = 'DELETE_DEVICE_SUCCESS';
export const DELETE_DEVICE_FAILED = 'DELETE_DEVICE_FAILED';

export const BULK_UPLOAD_DEVICES_START = 'BULK_UPLOAD_DEVICES_START';
export const BULK_UPLOAD_DEVICES_SUCCESS = 'BULK_UPLOAD_DEVICE_SUCCESS';
export const BUlK_UPLOAD_DEVICES_FAILED = 'BULK_UPLOAD_DEVICES_FAILED';
export const RETURN_ASSIGN_DEVICE = 'RETURN_ASSIGN_DEVICE';
export const ASSIGN_DEVICE = 'ASSIGN_DEVICE';

export const GET_VERIFICATION_CODE = 'GET_VERIFICATION_CODE';

export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START';
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS';
export const GET_PERMISSIONS_FAILED = 'GET_PERMISSIONS_FAILED';

export const UPDATE_PERMISSIONS_START = 'UPDATE_PERMISSIONS_START';
export const UPDATE_PERMISSIONS_SUCCESS = 'UPDATE_PERMISSIONS_SUCCESS';
export const UPDATE_PERMISSIONS_FAILED = 'UPDATE_PERMISSIONS_FAILED';

export const GET_SHIPMENT_DETAILS = 'GET_SHIPMENT_DETAILS';
export const GET_SHIPMENT_LOG = 'GET_SHIPMENT_LOG';
export const GET_SHIPMENT_LIST = 'GET_SHIPMENT_LIST';
export const UPDATE_SHIPMENT_STATUS = 'UPDATE_SHIPMENT_STATUS';
export const SAVE_SHIPMENT = 'SAVE_SHIPMENT';
export const SET_LOADING_SHIPLIST = 'SET_LOADING_SHIPLIST';
export const UPLOAD_FILTER_DATA = 'UPLOAD_FILTER_DATA';
//export const GET_SHIPMENT_CHART_DATA = 'GET_SHIPMENT_CHART_DATA';

export const GET_HELP = 'GET_HELP';
export const GET_EACH_DATA = 'GET_EACH_DATA';

export const GET_DASHBOARD_DETAILS_START = 'GET_DASHBOARD_DETAILS_START';
export const GET_DASHBOARD_DETAILS_SUCCESS = 'GET_DASHBOARD_DETAILS_SUCCESS';
export const GET_DASHBOARD_DETAILS_FAILED = 'GET_DASHBOARD_DETAILS_FAILED';

export const GET_DASHBOARD_SHIPMENT_LIST_START = 'GET_DASHBOARD_SHIPMENT_LIST_START';
export const GET_DASHBOARD_SHIPMENT_LIST_SUCCESS = 'GET_DASHBOARD_SHIPMENT_LIST_SUCCESS';
export const GET_DASHBOARD_SHIPMENT_LIST_FAILED = 'GET_DASHBOARD_SHIPMENT_LIST_FAILED';

export const GET_CONFIGURATION_LIST_START = 'GET_CONFIGURATION_LIST_START';
export const GET_CONFIGURATION_LIST_SUCCESS = 'GET_CONFIGURATION_LIST_SUCCESS';
export const GET_CONFIGURATION_LIST_FAILED = 'GET_CONFIGURATION_LIST_FAILED';

export const SET_CONFIGURATION_START = 'SET_CONFIGURATION_START';
export const SET_CONFIGURATION_SUCCESS = 'SET_CONFIGURATION_SUCCESS';
export const SET_CONFIGURATION_FAILED = 'SET_CONFIGURATION_FAILED';

export const SET_CONFIGURATION_ORDER_START = 'SET_CONFIGURATION_ORDER_START';
export const SET_CONFIGURATION_ORDER_SUCCESS = 'SET_CONFIGURATION_SUCCESS';
export const SET_CONFIGURATION_ORDER_FAILED = 'SET_CONFIGURATION_ORDER_FAILED';

export const GET_SEARCH_CATEGORY_START = 'GET_SEARCH_CATEGORY_START';
export const GET_SEARCH_CATEGORY_SUCCESS = 'GET_SEARCH_CATEGORY_SUCCESS';
export const GET_SEARCH_CATEGORY_FAILED = 'GET_SEARCH_CATEGORY_FAILED';

export const GET_SHIPMENT_LIST_MAP_START = 'GET_SHIPMENT_LIST_MAP_START';
export const GET_SHIPMENT_LIST_MAP_SUCCESS = 'GET_SHIPMENT_LIST_MAP_SUCCESS';
export const GET_SHIPMENT_LIST_MAP_FAILED = 'GET_SHIPMENT_LIST_MAP_FAILED';

export const GET_SHIPMENT_LIST_START = 'GET_SHIPMENT_LIST_START';
export const GET_SHIPMENT_LIST_SUCCESS = 'GET_SHIPMENT_LIST_SUCCESS';
export const GET_SHIPMENT_LIST_FAILED = 'GET_SHIPMENT_LIST_FAILED';

export const GET_COMPANY_LIST_START = 'GET_COMPANY_LIST_START';
export const GET_COMPANY_LIST_SUCCESS = 'GET_COMPANY_LIST_SUCCESS';
export const GET_COMPANY_LIST_FAILED = 'GET_COMPANY_LIST_FAILED';

export const GET_ACTIVE_COMPANY_LIST_START = 'GET_ACTIVE_COMPANY_LIST_START';
export const GET_ACTIVE_COMPANY_LIST_STOP = 'GET_ACTIVE_COMPANY_LIST_STOP';
export const GET_ACTIVE_COMPANY_LIST_SUCCESS = 'GET_ACTIVE_COMPANY_LIST_SUCCESS';
export const GET_ACTIVE_COMPANY_LIST_FAILED = 'GET_ACTIVE_COMPANY_LIST_FAILED';

export const GET_SHIPMENT_DETAILS_START = 'GET_SHIPMENT_DETAILS_START';
export const GET_SHIPMENT_DETAILS_SUCCESS = 'GET_SHIPMENT_DETAILS_SUCCESS';
export const GET_SHIPMENT_DETAILS_FAILED = 'GET_SHIPMENT_DETAILS_FAILED';

export const GET_SHIPMENT_TIMELINE_START = 'GET_SHIPMENT_TIMELINE_START';
export const GET_SHIPMENT_TIMELINE_SUCCESS = 'GET_SHIPMENT_TIMELINE_SUCCESS';
export const GET_SHIPMENT_TIMELINE_FAILED = 'GET_SHIPMENT_TIMELINE_FAILED';
export const GET_TC_DATA = 'GET_TC_DATA';
export const GET_TC_DATA_START = 'GET_TC_DATA_START';

export const GET_SHIPMENT_ROUTE_START = 'GET_SHIPMENT_ROUTE_START';
export const GET_SHIPMENT_ROUTE_SUCCESS = 'GET_SHIPMENT_ROUTE_SUCCESS';
export const GET_SHIPMENT_ROUTE_FAILED = 'GET_SHIPMENT_ROUTE_FAILED';

export const SET_LIVE_DATA = 'SET_LIVE_DATA';

export const CREATE_NEW_SHIPMENT_START = 'CREATE_NEW_SHIPMENT_START';
export const CREATE_NEW_SHIPMENT_SUCCESS = 'CREATE_NEW_SHIPMENT_SUCCESS';
export const CREATE_NEW_SHIPMENT_FAILED = 'CREATE_NEW_SHIPMENT_FAILED';

export const CREATE_NEW_DEVICE_SHIPMENT_START = 'CREATE_NEW_DEVICE_SHIPMENT_START';
export const CREATE_NEW_DEVICE_SHIPMENT_SUCCESS = 'CREATE_NEW_DEVICE_SHIPMENT_SUCCESS';
export const CREATE_NEW_DEVICE_SHIPMENT_FAILED = 'CREATE_NEW_DEVICE_SHIPMENT_FAILED';

export const SET_SHIPMENT_UPDATE_START = 'SET_SHIPMENT_UPDATE_START';
export const SET_SHIPMENT_UPDATE_SUCCESS = 'SET_SHIPMENT_UPDATE_SUCCESS';
export const SET_SHIPMENT_UPDATE_FAILED = 'SET_SHIPMENT_UPDATE_FAILED';

export const UPDATE_SHIPMENT_DETAILS_START = 'UPDATE_SHIPMENT_DETAILS_START';
export const UPDATE_SHIPMENT_DETAILS_SUCCESS = 'UPDATE_SHIPMENT_DETAILS_SUCCESS';
export const UPDATE_SHIPMENT_DETAILS_FAILED = 'UPDATE_SHIPMENT_DETAILS_FAILED';

export const GET_SHIPMENT_CONFIGURATION_START = 'GET_SHIPMENT_CONFIGURATION_START';
export const GET_SHIPMENT_CONFIGURATION_SUCCESS = 'GET_SHIPMENT_CONFIGURATION_SUCCESS';
export const GET_SHIPMENT_CONFIGURATION_FAILED = 'GET_SHIPMENT_CONFIGURATION_FAILED';

export const UPDATE_SHIPMENT_CONFIGURATION_START = 'UPDATE_SHIPMENT_CONFIGURATION_START';
export const UPDATE_SHIPMENT_CONFIGURATION_SUCCESS = 'UPDATE_SHIPMENT_CONFIGURATION_SUCCESS';
export const UPDATE_SHIPMENT_CONFIGURATION_FAILED = 'UPDATE_SHIPMENT_CONFIGURATION_FAILED';

export const GET_SHIPMENT_TRACKER_DATA_START = 'GET_SHIPMENT_TRACKER_DATA_START';
export const GET_SHIPMENT_TRACKER_DATA_SUCCESS = 'GET_SHIPMENT_TRACKER_DATA_SUCCESS';
export const GET_SHIPMENT_TRACKER_DATA_FAILED = 'GET_SHIPMENT_TRACKER_DATA_FAILED';

export const USER_LOGOUT = 'USER_LOGOUT';

export const GET_CARRIERS_START = 'GET_CARRIERS_START';
export const GET_CARRIERS_SUCCESS = 'GET_CARRIERS_SUCCESS';
export const GET_CARRIERS_FAILED = 'GET_CARRIERS_FAILED';

export const GET_NOTIFICATIONS_START = 'GET_NOTIFICATIONS_START';
export const GET_NOTIFICATIONS_SUCCESS = 'GET_NOTIFICATIONS_SUCCESS';
export const GET_NOTIFICATIONS_FAILED = 'GET_NOTIFICATIONS_FAILED';

export const SET_NOTIFICATION_READ_START = 'SET_NOTIFICATION_READ_START';
export const SET_NOTIFICATION_READ_SUCCESS = 'SET_NOTIFICATION_READ_SUCCESS';
export const SET_NOTIFICATION_READ_FAILED = 'SET_NOTIFICATION_READ_FAILED';

export const GET_UNREAD_NOTIFICATIONS_START = 'GET_UNREAD_NOTIFICATIONS_START';
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_UNREAD_NOTIFICATIONS_SUCCESS';
export const GET_UNREAD_NOTIFICATIONS_FAILED = 'GET_UNREAD_NOTIFICATIONS_FAILED';
export const GET_ALL_THRESHOLD_EVENTS = 'GET_ALL_THRESHOLD_EVENTS';
export const GET_COMPANY_THRESHOLD_VALUES = 'GET_COMPANY_THRESHOLD_VALUES';
export const UPDATE_COMPANY_THRESHOLD_VALUES = 'UPDATE_COMPANY_THRESHOLD_VALUES';
export const UPDATE_CONFIGURATION = 'UPDATE_CONFIGURATION';
export const GET_COMPANY_SHIPMENT_CREATION_FOR_CARRIER = 'GET_COMPANY_SHIPMENT_CREATION_FOR_CARRIER';

export const SAVE_GEO_FENCE_START = 'SAVE_GEO_FENCE_START';
export const SAVE_GEO_FENCE_SUCCESS = 'SAVE_GEO_FENCE_SUCCESS';
export const SAVE_GEO_FENCE_FAILED = 'SAVE_GEO_FENCE_FAILED';

export const DELETE_GEO_FENCE_START = 'DELETE_GEO_FENCE_START';
export const DELETE_GEO_FENCE_SUCCESS = 'DELETE_GEO_FENCE_SUCCESS';
export const DELETE_GEO_FENCE_FAILED = 'DELETE_GEO_FENCE_FAILED';

export const GET_GROUPS_LIST_START = 'GET_GROUPS_LIST_START';
export const GET_GROUPS_LIST_SUCCESS = 'GET_GROUPS_LIST_SUCCESS';
export const GET_GROUPS_LIST_FAILED = 'GET_GROUPS_LIST_FAILED';

export const GET_GROUPS_START = 'GET_GROUPS_START';
export const GET_GROUPS_SUCCESS = 'GET_GROUPS_SUCCESS';
export const GET_GROUPS_FAILED = 'GET_GROUPS_FAILED';

export const GET_GROUPS_USERS_LIST_START = 'GET_GROUPS_USER_LIST_START';
export const GET_GROUPS_USERS_SUCCESS = 'GET_GROUPS_USERS_SUCCESS';
export const GET_GROUPS_DEVICES_LIST_START = 'GET_GROUPS_DEVICE_LIST_START';
export const GET_GROUPS_DEVICES_LIST_SUCCESS = 'GET_GROUPS_DEVICES_SUCCESS';
export const GET_GROUPS_COMPANIES_LIST_START = 'GET_GROUPS_COMPANIES_LIST_START';
export const GET_GROUPS_COMPANIES_LIST_SUCCESS = 'GET_GROUPS_COMPANIES_LIST_SUCCESS';
export const GET_GROUP_SHIPMENT_COUNT_SUCCESS = 'GET_GROUP_SHIPMENT_COUNT_SUCCESS';

export const ADD_NEW_GROUP_START = 'ADD_NEW_GROUP_START';
export const ADD_NEW_GROUP_SUCCESS = 'ADD_NEW_GROUP_SUCCESS';
export const ADD_NEW_GROUP_FAILED = 'ADD_NEW_GROUP_FAILED';
export const CLEAR_GROUP_FORM_ERRORS = 'CLEAR_GROUP_FORM_ERRORS';
export const SHOW_NEW_GROUP = 'SHOW_NEW_GROUP';

export const UPDATE_GROUP_START = 'UPDATE_GROUP_START';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

export const DELETE_GROUP_START = 'DELETE_GROUP_START';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';

export const VIEW_GROUP_START = 'VIEW_GROUP_START';
export const VIEW_GROUP_SUCCESS = 'VIEW_GROUP_SUCCESS';
export const VIEW_GROUP_FAILED = 'VIEW_GROUP_FAILED';

export const ASSIGN_COMPANIES_TO_GROUP_START = 'ASSIGN_COMPANIES_TO_GROUP_START';
export const ASSIGN_COMPANIES_TO_GROUP_SUCCESS = 'ASSIGN_COMPANIES_TO_GROUP_SUCCESS';
export const ASSIGN_COMPANIES_TO_GROUP_FAILED = 'ASSIGN_COMPANIES_TO_GROUP_FAILED';

export const REMOVE_ASSIGNED_COMPANIES_START = 'REMOVE_ASSIGNED_COMPANIES_START';
export const REMOVE_ASSIGNED_COMPANIES_SUCCESS = 'REMOVE_ASSIGNED_COMPANIES_SUCCESS';
export const REMOVE_ASSIGNED_COMPANIES_FAILED = 'REMOVE_ASSIGNED_COMPANIES_FAILED';

export const MAKE_GROUP_ADMIN_START = 'MAKE_GROUP_ADMIN_START';
export const MAKE_GROUP_ADMIN_SUCCESS = 'MAKE_GROUP_ADMIN_SUCCESS';
export const MAKE_GROUP_ADMIN_FAILED = 'MAKE_GROUP_ADMIN_FAILED';

export const GET_INSIGHTS_DATA_START = 'GET_INSIGHTS_DATA_START';
export const GET_INSIGHTS_DATA_STOP = 'GET_INSIGHTS_DATA_STOP';
export const GET_INSIGHTS_DATA_SUCCESS = 'GET_INSIGHTS_DATA_SUCCESS';
export const GET_INSIGHTS_DATA_FAILED = 'GET_INSIGHTS_DATA_FAILED';

export const NO_NETWORK_CONNECTION = 'NO_NETWORK_CONNECTION';
export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

export const GET_INSIGHTS_LOC_START = 'GET_INSIGHTS_LOC_START';
export const GET_INSIGHTS_LOC_SUCCESS = 'GET_INSIGHTS_LOC_SUCCESS';
export const GET_INSIGHTS_LOC_FAILED = 'GET_INSIGHTS_LOC_FAILED';

export const GET_LOC_COUNTRIES_START = 'GET_LOC_COUNTRIES_START';
export const GET_LOC_COUNTRIES_SUCCESS = 'GET_LOC_COUNTRIES_SUCCESS';
export const GET_LOC_COUNTRIES_FAILED = 'GET_LOC_COUNTRIES_FAILED';

export const GET_LOC_STATES_START = 'GET_LOC_STATES_START';
export const GET_LOC_STATES_SUCCESS = 'GET_LOC_STATES_SUCCESS';
export const GET_LOC_STATES_FAILED = 'GET_LOC_STATES_FAILED';

export const GET_LOC_CITIES_START = 'GET_LOC_CITIES_START';
export const GET_LOC_CITIES_SUCCESS = 'GET_LOC_CITIES_SUCCESS';
export const GET_LOC_CITIES_FAILED = 'GET_LOC_CITIES_FAILED';

export const GET_LOC_ROUTES_START = 'GET_LOC_ROUTES_START';
export const GET_LOC_ROUTES_SUCCESS = 'GET_LOC_ROUTES_SUCCESS';
export const GET_LOC_ROUTES_FAILED = 'GET_LOC_ROUTES_FAILED';

export const GET_CONFIGURATION_PROFILE_LIST_START = 'GET_CONFIGURATION_PROFILE_LIST_START';
export const GET_CONFIGURATION_PROFILE_LIST_SUCCESS = 'GET_CONFIGURATION_PROFILE_LIST_SUCCESS';
export const GET_CONFIGURATION_PROFILE_LIST_FAILED = 'GET_CONFIGURATION_PROFILE_LIST_FAILED';

export const GET_ALERT_PROFILE_LIST_START = 'GET_ALERT_PROFILE_LIST_START';
export const GET_ALERT_PROFILE_LIST_SUCCESS = 'GET_ALERT_PROFILE_LIST_SUCCESS';
export const GET_ALERT_PROFILE_LIST_FAILED = 'GET_ALERT_PROFILE_LIST_FAILED';

export const GET_CONFIGURATION_PROFILE_START = 'GET_CONFIGURATION_PROFILE_START';
export const GET_CONFIGURATION_PROFILE_SUCCESS = 'GET_CONFIGURATION_PROFILE_SUCCESS';
export const GET_CONFIGURATION_PROFILE_FAILED = 'GET_CONFIGURATION_PROFILE_FAILED';

export const GET_ALERT_PROFILE_START = 'GET_ALERT_PROFILE_START';
export const GET_ALERT_PROFILE_SUCCESS = 'GET_ALERT_PROFILE_SUCCESS';
export const GET_ALERT_PROFILE_FAILED = 'GET_ALERT_PROFILE_FAILED';

export const SAVE_CONFIGURATION_PROFILE_START = 'SAVE_CONFIGURATION_PROFILE_START';
export const SAVE_CONFIGURATION_PROFILE_SUCCESS = 'SAVE_CONFIGURATION_PROFILE_SUCCESS';
export const SAVE_CONFIGURATION_PROFILE_FAILED = 'SAVE_CONFIGURATION_PROFILE_FAILED';

export const SAVE_ALERT_PROFILE_START = 'SAVE_ALERT_PROFILE_START';
export const SAVE_ALERT_PROFILE_SUCCESS = 'SAVE_ALERT_PROFILE_SUCCESS';
export const SAVE_ALERT_PROFILE_FAILED = 'SAVE_ALERT_PROFILE_FAILED';

export const DELETE_CONFIGURATION_PROFILE_START = 'DELETE_CONFIGURATION_PROFILE_START';
export const DELETE_CONFIGURATION_PROFILE_SUCCESS = 'DELETE_CONFIGURATION_PROFILE_SUCCESS';
export const DELETE_CONFIGURATION_PROFILE_FAILED = 'DELETE_CONFIGURATION_PROFILE_FAILED';

export const DELETE_ALERT_PROFILE_START = 'DELETE_ALERT_PROFILE_START';
export const DELETE_ALERT_PROFILE_SUCCESS = 'DELETE_ALERT_PROFILE_SUCCESS';
export const DELETE_ALERT_PROFILE_FAILED = 'DELETE_ALERT_PROFILE_FAILED';

export const GET_COMPANY_DEVICES_TRACKING_START = 'GET_COMPANY_DEVICES_TRACKING_START';
export const GET_COMPANY_DEVICES_TRACKING_SUCCESS = 'GET_COMPANY_DEVICES_TRACKING_SUCCESS';
export const GET_COMPANY_DEVICES_TRACKING_FAILED = 'GET_COMPANY_DEVICES_TRACKING_FAILED';

export const GET_COMPANY_DEVICES_TRACKING_STATUS_START = 'GET_COMPANY_DEVICES_TRACKING_STATUS_START';
export const GET_COMPANY_DEVICES_TRACKING_STATUS_SUCCESS = 'GET_COMPANY_DEVICES_TRACKING_STATUS_SUCCESS';
export const GET_COMPANY_DEVICES_TRACKING_STATUS_FAILED = 'GET_COMPANY_DEVICES_TRACKING_STATUS_FAILED';

export const GET_DEVICE_DETAILS_START = 'GET_DEVICE_DETAILS_START';
export const GET_DEVICE_DETAILS_SUCCESS = 'GET_DEVICE_DETAILS_SUCCESS';
export const GET_DEVICE_DETAILS_FAILED = 'GET_DEVICE_DETAILS_FAILED';

export const UPDATE_ASSET_LIVE_DATA = 'UPDATE_ASSET_LIVE_DATA';

export const GET_COMPANY_GEOFENCES_START = 'GET_COMPANY_GEOFENCES_START';
export const GET_COMPANY_GEOFENCES_SUCCESS = 'GET_COMPANY_GEOFENCES_SUCCESS';
export const GET_COMPANY_GEOFENCES_FAILED = 'GET_COMPANY_GEOFENCES_FAILED';

export const UPDATE_DEVICE_DETAILS_START = 'UPDATE_DEVICE_DETAILS_START';
export const UPDATE_DEVICE_DETAILS_SUCCESS = 'UPDATE_DEVICE_DETAILS_SUCCESS';
export const UPDATE_DEVICE_DETAILS_FAILED = 'UPDATE_DEVICE_DETAILS_FAILED';

export const UPDATE_COMPANY_GEOFENCES_START = 'UPDATE_COMPANY_GEOFENCES_START';
export const UPDATE_COMPANY_GEOFENCES_SUCCESS = 'UPDATE_COMPANY_GEOFENCES_SUCCESS';
export const UPDATE_COMPANY_GEOFENCES_FAILED = 'UPDATE_COMPANY_GEOFENCES_FAILED';

export const GET_PRODUCT_TYPES_START = 'GET_PRODUCT_TYPES_START';
export const GET_PRODUCT_TYPES_SUCCESS = 'GET_PRODUCT_TYPES_SUCCESS';
export const GET_PRODUCT_TYPES_FAILED = 'GET_PRODUCT_TYPES_FAILED';

export const UPDATE_PRODUCT_TYPE_START = 'UPDATE_PRODUCT_TYPE_START';
export const UPDATE_PRODUCT_TYPE_SUCCESS = 'UPDATE_PRODUCT_TYPE_SUCCESS';
export const UPDATE_PRODUCT_TYPE_FAILED = 'UPDATE_PRODUCT_TYPE_FAILED';

export const GET_COMPANY_PRODUCT_STATUS_TEMPLATES_START = 'GET_COMPANY_PRODUCT_STATUS_TEMPLATES_START';
export const GET_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS = 'GET_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS';
export const GET_COMPANY_PRODUCT_STATUS_TEMPLATES_FAILED = 'GET_COMPANY_PRODUCT_STATUS_TEMPLATES_FAILED';

export const UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_START = 'UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_START';
export const UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS = 'UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_SUCCESS';
export const UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_FAILED = 'UPDATE_COMPANY_PRODUCT_STATUS_TEMPLATES_FAILED';

export const GET_PRODUCT_PROFILE_LIST_START = 'GET_PRODUCT_PROFILE_LIST_START';
export const GET_PRODUCT_PROFILE_LIST_SUCCESS = 'GET_PRODUCT_PROFILE_LIST_SUCCESS';
export const GET_PRODUCT_PROFILE_LIST_FAILED = 'GET_PRODUCT_PROFILE_LIST_FAILED';

export const GET_PRODUCT_PROFILE_START = 'GET_PRODUCT_PROFILE_START';
export const GET_PRODUCT_PROFILE_SUCCESS = 'GET_PRODUCT_PROFILE_SUCCESS';
export const GET_PRODUCT_PROFILE_FAILED = 'GET_PRODUCT_PROFILE_FAILED';

export const SAVE_PRODUCT_PROFILE_START = 'SAVE_PRODUCT_PROFILE_START';
export const SAVE_PRODUCT_PROFILE_SUCCESS = 'SAVE_PRODUCT_PROFILE_SUCCESS';
export const SAVE_PRODUCT_PROFILE_FAILED = 'SAVE_PRODUCT_PROFILE_FAILED';

export const DELETE_PRODUCT_PROFILE_START = 'DELETE_PRODUCT_PROFILE_START';
export const DELETE_PRODUCT_PROFILE_SUCCESS = 'DELETE_PRODUCT_PROFILE_SUCCESS';
export const DELETE_PRODUCT_PROFILE_FAILED = 'DELETE_PRODUCT_PROFILE_FAILED';

export const GET_PRODUCT_TYPE_LIST_START = 'GET_PRODUCT_TYPE_LIST_START';
export const GET_PRODUCT_TYPE_LIST_SUCCESS = 'GET_PRODUCT_TYPE_LIST_SUCCESS';
export const GET_PRODUCT_TYPE_LIST_FAILED = 'GET_PRODUCT_TYPE_LIST_FAILED';

export const SAVE_PRODUCT_TYPE_START = 'SAVE_PRODUCT_TYPE_START';
export const SAVE_PRODUCT_TYPE_SUCCESS = 'SAVE_PRODUCT_TYPE_SUCCESS';
export const SAVE_PRODUCT_TYPE_FAILED = 'SAVE_PRODUCT_TYPE_FAILED';

export const DELETE_PRODUCT_TYPE_START = 'DELETE_PRODUCT_TYPE_START';
export const DELETE_PRODUCT_TYPE_SUCCESS = 'DELETE_PRODUCT_TYPE_SUCCESS';
export const DELETE_PRODUCT_TYPE_FAILED = 'DELETE_PRODUCT_TYPE_FAILED';

export const GET_PRODUCT_TYPE_START = 'GET_PRODUCT_TYPE_START';
export const GET_PRODUCT_TYPE_SUCCESS = 'GET_PRODUCT_TYPE_SUCCESS';

export const GET_LANE_ID_LIST_START = 'GET_LANE_ID_LIST_START';
export const GET_LANE_ID_LIST_SUCCESS = 'GET_LANE_ID_LIST_SUCCESS';
export const GET_LANE_ID_LIST_FAILED = 'GET_LANE_ID_LIST_FAILED';

export const SAVE_LANE_ID_START = 'SAVE_LANE_ID_START';
export const SAVE_LANE_ID_SUCCESS = 'SAVE_LANE_ID_SUCCESS';
export const SAVE_LANE_ID_FAILED = 'SAVE_LANE_ID_FAILED';

export const DELETE_LANE_ID_START = 'DELETE_LANE_ID_START';
export const DELETE_LANE_ID_SUCCESS = 'DELETE_LANE_ID_SUCCESS';
export const DELETE_LANE_ID_FAILED = 'DELETE_LANE_ID_FAILED';

export const GET_LANE_ID_START = 'GET_LANE_ID_START';
export const GET_LANE_ID_SUCCESS = 'GET_LANE_ID_SUCCESS';

export const GET_PACKAGE_TYPE_LIST_START = 'GET_PACKAGE_TYPE_LIST_START';
export const GET_PACKAGE_TYPE_LIST_SUCCESS = 'GET_PACKAGE_TYPE_LIST_SUCCESS';
export const GET_PACKAGE_TYPE_LIST_FAILED = 'GET_PACKAGE_TYPE_LIST_FAILED';

export const SAVE_PACKAGE_TYPE_START = 'SAVE_PACKAGE_TYPE_START';
export const SAVE_PACKAGE_TYPE_SUCCESS = 'SAVE_PACKAGE_TYPE_SUCCESS';
export const SAVE_PACKAGE_TYPE_FAILED = 'SAVE_PACKAGE_TYPE_FAILED';

export const DELETE_PACKAGE_TYPE_START = 'DELETE_PACKAGE_TYPE_START';
export const DELETE_PACKAGE_TYPE_SUCCESS = 'DELETE_PACKAGE_TYPE_SUCCESS';
export const DELETE_PACKAGE_TYPE_FAILED = 'DELETE_PACKAGE_TYPE_FAILED';

export const GET_PACKAGE_TYPE_START = 'GET_PACKAGE_TYPE_START';
export const GET_PACKAGE_TYPE_SUCCESS = 'GET_PACKAGE_TYPE_SUCCESS';

export const GET_SHIPMENT_REPORTS_START = 'GET_SHIPMENT_REPORTS_START';
export const GET_SHIPMENT_REPORTS_SUCCESS = 'GET_SHIPMENT_REPORTS_SUCCESS';
export const GET_SHIPMENT_REPORTS_FAILED = 'GET_SHIPMENT_REPORTS_FAILED';

export const CREATE_SHIPMENT_REPORT_START = 'CREATE_SHIPMENT_REPORT_START';
export const CREATE_SHIPMENT_REPORT_SUCCESS = 'CREATE_SHIPMENT_REPORT_SUCCESS';
export const CREATE_SHIPMENT_REPORT_FAILED = 'CREATE_SHIPMENT_REPORT_FAILED';

export const DELETE_SHIPMENT_REPORT_START = 'DELETE_SHIPMENT_REPORT_START';
export const DELETE_SHIPMENT_REPORT_SUCCESS = 'DELETE_SHIPMENT_REPORT_SUCCESS';
export const DELETE_SHIPMENT_REPORT_FAILED = 'DELETE_SHIPMENT_REPORT_FAILED';
export const GET_SHIPMENT_PROFILE_LIST_START = 'GET_SHIPMENT_PROFILE_LIST_START';
export const GET_SHIPMENT_PROFILE_LIST_SUCCESS = 'GET_SHIPMENT_PROFILE_LIST_SUCCESS';
export const GET_SHIPMENT_PROFILE_LIST_FAILED = 'GET_SHIPMENT_PROFILE_LIST_FAILED';

export const GET_SHIPMENT_PROFILE_START = 'GET_SHIPMENT_PROFILE_START';
export const GET_SHIPMENT_PROFILE_SUCCESS = 'GET_SHIPMENT_PROFILE_SUCCESS';
export const GET_SHIPMENT_PROFILE_FAILED = 'GET_SHIPMENT_PROFILE_FAILED';

export const SAVE_SHIPMENT_PROFILE_START = 'SAVE_SHIPMENT_PROFILE_START';
export const SAVE_SHIPMENT_PROFILE_SUCCESS = 'SAVE_SHIPMENT_PROFILE_SUCCESS';
export const SAVE_SHIPMENT_PROFILE_FAILED = 'SAVE_SHIPMENT_PROFILE_FAILED';

export const DELETE_SHIPMENT_PROFILE_START = 'DELETE_SHIPMENT_PROFILE_START';
export const DELETE_SHIPMENT_PROFILE_SUCCESS = 'DELETE_SHIPMENT_PROFILE_SUCCESS';
export const DELETE_SHIPMENT_PROFILE_FAILED = 'DELETE_SHIPMENT_PROFILE_FAILED';

export const GET_CARRIER_TYPE_LIST_START = 'GET_CARRIER_TYPE_LIST_START';
export const GET_CARRIER_TYPE_LIST_SUCCESS = 'GET_CARRIER_TYPE_LIST_SUCCESS';
export const GET_CARRIER_TYPE_LIST_FAILED = 'GET_CARRIER_TYPE_LIST_FAILED';

export const SAVE_CARRIER_TYPE_START = 'SAVE_CARRIER_TYPE_START';
export const SAVE_CARRIER_TYPE_SUCCESS = 'SAVE_CARRIER_TYPE_SUCCESS';
export const SAVE_CARRIER_TYPE_FAILED = 'SAVE_CARRIER_TYPE_FAILED';

export const DELETE_CARRIER_TYPE_START = 'DELETE_CARRIER_TYPE_START';
export const DELETE_CARRIER_TYPE_SUCCESS = 'DELETE_CARRIER_TYPE_SUCCESS';
export const DELETE_CARRIER_TYPE_FAILED = 'DELETE_CARRIER_TYPE_FAILED';

export const GET_CARRIER_TYPE_START = 'GET_CARRIER_TYPE_START';
export const GET_CARRIER_TYPE_SUCCESS = 'GET_CARRIER_TYPE_SUCCESS';

export const UPLOAD_PDF_START = 'UPLOAD_PDF_START';
export const UPLOAD_PDF_SUCCESS = 'UPLOAD_PDF_SUCCESS';
export const UPLOAD_PDF_FAILED = 'UPLOAD_PDF_FAILED';

export const GET_PDF_LIST_START = 'GET_PDF_LIST_START';
export const GET_PDF_LIST_SUCCESS = 'GET_PDF_LIST_SUCCESS';
export const GET_PDF_LIST_FAILED = 'GET_PDF_LIST_FAILED';

export const GET_PDF_DETAILS_START = 'GET_PDF_DETAILS_START';
export const GET_PDF_DETAILS_SUCCESS = 'GET_PDF_DETAILS_SUCCESS';
export const GET_PDF_DETAILS_FAILED = 'GET_PDF_DETAILS_FAILED';

export const GET_FILTERS_LIST_START = 'GET_FILTERS_LIST_START';
export const GET_FILTERS_LIST_SUCCESS = 'GET_FILTERS_LIST_SUCCESS';
export const GET_FILTERS_LIST_FAILED = 'GET_FILTERS_LIST_FAILED';

export const BULK_UPLOAD_PDF_START = 'BULK_UPLOAD_PDF_START';
export const BULK_UPLOAD_PDF_SUCCESS = 'BULK_UPLOAD_PDF_SUCCESS';
export const BULK_UPLOAD_PDF_FAILED = 'BULK_UPLOAD_PDF_FAILED';

export const GET_BULK_PDF_LIST_START = 'GET_BULK_PDF_LIST_START';
export const GET_BULK_PDF_LIST_SUCCESS = 'GET_BULK_PDF_LIST_SUCCESS';
export const GET_BULK_PDF_LIST_FAILED = 'GET_BULK_PDF_LIST_FAILED';

export const GET_PDF_PARSER_PROFILE_LIST_START = 'GET_PDF_PARSER_PROFILE_LIST_START';
export const GET_PDF_PARSER_PROFILE_LIST_SUCCESS = 'GET_PDF_PARSER_PROFILE_LIST_SUCCESS';
export const GET_PDF_PARSER_PROFILE_LIST_FAILED = 'GET_PDF_PARSER_PROFILE_LIST_FAILED';

export const GET_PDF_PARSER_PROFILE_START = 'GET_PDF_PARSER_PROFILE_START';
export const GET_PDF_PARSER_PROFILE_SUCCESS = 'GET_PDF_PARSER_PROFILE_SUCCESS';
export const GET_PDF_PARSER_PROFILE_FAILED = 'GET_PDF_PARSER_PROFILE_FAILED';

export const SAVE_PDF_PARSER_PROFILE_START = 'SAVE_PDF_PARSER_PROFILE_START';
export const SAVE_PDF_PARSER_PROFILE_SUCCESS = 'SAVE_PDF_PARSER_PROFILE_SUCCESS';
export const SAVE_PDF_PARSER_PROFILE_FAILED = 'SAVE_PDF_PARSER_PROFILE_FAILED';

export const DELETE_PDF_PARSER_PROFILE_START = 'DELETE_PDF_PARSER_PROFILE_START';
export const DELETE_PDF_PARSER_PROFILE_SUCCESS = 'DELETE_PDF_PARSER_PROFILE_SUCCESS';
export const DELETE_PDF_PARSER_PROFILE_FAILED = 'DELETE_PDF_PARSER_PROFILE_FAILED';

export const GET_PDF_PRODUCT_PROFILE_LIST_START = 'GET_PDF_PRODUCT_PROFILE_LIST_START';
export const GET_PDF_PRODUCT_PROFILE_LIST_SUCCESS = 'GET_PDF_PRODUCT_PROFILE_LIST_SUCCESS';
export const GET_PDF_PRODUCT_PROFILE_LIST_FAILED = 'GET_PDF_PRODUCT_PROFILE_LIST_FAILED';

export const GET_PDF_PRODUCT_PROFILE_START = 'GET_PDF_PRODUCT_PROFILE_START';
export const GET_PDF_PRODUCT_PROFILE_SUCCESS = 'GET_PDF_PRODUCT_PROFILE_SUCCESS';
export const GET_PDF_PRODUCT_PROFILE_FAILED = 'GET_PDF_PRODUCT_PROFILE_FAILED';

export const SAVE_PDF_PRODUCT_PROFILE_START = 'SAVE_PDF_PRODUCT_PROFILE_START';
export const SAVE_PDF_PRODUCT_PROFILE_SUCCESS = 'SAVE_PDF_PRODUCT_PROFILE_SUCCESS';
export const SAVE_PDF_PRODUCT_PROFILE_FAILED = 'SAVE_PDF_PRODUCT_PROFILE_FAILED';

export const DELETE_PDF_PRODUCT_PROFILE_START = 'DELETE_PDF_PRODUCT_PROFILE_START';
export const DELETE_PDF_PRODUCT_PROFILE_SUCCESS = 'DELETE_PDF_PRODUCT_PROFILE_SUCCESS';
export const DELETE_PDF_PRODUCT_PROFILE_FAILED = 'DELETE_PDF_PRODUCT_PROFILE_FAILED';

export const GET_ACTIVE_INGREDIENT_LIST_START = 'GET_ACTIVE_INGREDIENT_LIST_START';
export const GET_ACTIVE_INGREDIENT_LIST_SUCCESS = 'GET_ACTIVE_INGREDIENT_LIST_SUCCESS';
export const GET_ACTIVE_INGREDIENT_LIST_FAILED = 'GET_ACTIVE_INGREDIENT_LIST_FAILED';

export const SAVE_ACTIVE_INGREDIENT_START = 'SAVE_ACTIVE_INGREDIENT_START';
export const SAVE_ACTIVE_INGREDIENT_SUCCESS = 'SAVE_ACTIVE_INGREDIENT_SUCCESS';
export const SAVE_ACTIVE_INGREDIENT_FAILED = 'SAVE_ACTIVE_INGREDIENT_FAILED';

export const DELETE_ACTIVE_INGREDIENT_START = 'DELETE_ACTIVE_INGREDIENT_START';
export const DELETE_ACTIVE_INGREDIENT_SUCCESS = 'DELETE_ACTIVE_INGREDIENT_SUCCESS';
export const DELETE_ACTIVE_INGREDIENT_FAILED = 'DELETE_ACTIVE_INGREDIENT_FAILED';

export const GET_ACTIVE_INGREDIENT_START = 'GET_ACTIVE_INGREDIENT_START';
export const GET_ACTIVE_INGREDIENT_SUCCESS = 'GET_ACTIVE_INGREDIENT_SUCCESS';

export const GET_STRENGTH_LIST_START = 'GET_STRENGTH_LIST_START';
export const GET_STRENGTH_LIST_SUCCESS = 'GET_STRENGTH_LIST_SUCCESS';
export const GET_STRENGTH_LIST_FAILED = 'GET_STRENGTH_LIST_FAILED';

export const SAVE_STRENGTH_START = 'SAVE_STRENGTH_START';
export const SAVE_STRENGTH_SUCCESS = 'SAVE_STRENGTH_SUCCESS';
export const SAVE_STRENGTH_FAILED = 'SAVE_STRENGTH_FAILED';

export const DELETE_STRENGTH_START = 'DELETE_STRENGTH_START';
export const DELETE_STRENGTH_SUCCESS = 'DELETE_STRENGTH_SUCCESS';
export const DELETE_STRENGTH_FAILED = 'DELETE_STRENGTH_FAILED';

export const GET_STRENGTH_START = 'GET_STRENGTH_START';
export const GET_STRENGTH_SUCCESS = 'GET_STRENGTH_SUCCESS';

export const GET_PDF_LANE_ID_LIST_START = 'GET_PDF_LANE_ID_LIST_START';
export const GET_PDF_LANE_ID_LIST_SUCCESS = 'GET_PDF_LANE_ID_LIST_SUCCESS';
export const GET_PDF_LANE_ID_LIST_FAILED = 'GET_PDF_LANE_ID_LIST_FAILED';

export const SAVE_PDF_LANE_ID_START = 'SAVE_PDF_LANE_ID_START';
export const SAVE_PDF_LANE_ID_SUCCESS = 'SAVE_PDF_LANE_ID_SUCCESS';
export const SAVE_PDF_LANE_ID_FAILED = 'SAVE_PDF_LANE_ID_FAILED';

export const DELETE_PDF_LANE_ID_START = 'DELETE_PDF_LANE_ID_START';
export const DELETE_PDF_LANE_ID_SUCCESS = 'DELETE_PDF_LANE_ID_SUCCESS';
export const DELETE_PDF_LANE_ID_FAILED = 'DELETE_PDF_LANE_ID_FAILED';

export const GET_PDF_LANE_ID_START = 'GET_PDF_LANE_ID_START';
export const GET_PDF_LANE_ID_SUCCESS = 'GET_PDF_LANE_ID_SUCCESS';

export const GET_PDF_PACKAGE_TYPE_LIST_START = 'GET_PDF_PACKAGE_TYPE_LIST_START';
export const GET_PDF_PACKAGE_TYPE_LIST_SUCCESS = 'GET_PDF_PACKAGE_TYPE_LIST_SUCCESS';
export const GET_PDF_PACKAGE_TYPE_LIST_FAILED = 'GET_PDF_PACKAGE_TYPE_LIST_FAILED';

export const SAVE_PDF_PACKAGE_TYPE_START = 'SAVE_PDF_PACKAGE_TYPE_START';
export const SAVE_PDF_PACKAGE_TYPE_SUCCESS = 'SAVE_PDF_PACKAGE_TYPE_SUCCESS';
export const SAVE_PDF_PACKAGE_TYPE_FAILED = 'SAVE_PDF_PACKAGE_TYPE_FAILED';

export const DELETE_PDF_PACKAGE_TYPE_START = 'DELETE_PDF_PACKAGE_TYPE_START';
export const DELETE_PDF_PACKAGE_TYPE_SUCCESS = 'DELETE_PDF_PACKAGE_TYPE_SUCCESS';
export const DELETE_PDF_PACKAGE_TYPE_FAILED = 'DELETE_PDF_PACKAGE_TYPE_FAILED';

export const GET_PDF_PACKAGE_TYPE_START = 'GET_PDF_PACKAGE_TYPE_START';
export const GET_PDF_PACKAGE_TYPE_SUCCESS = 'GET_PDF_PACKAGE_TYPE_SUCCESS';

export const GET_LANES_LIST_START = 'GET_LANES_LIST_START';
export const GET_LANES_LIST_SUCCESS = 'GET_LANES_LIST_SUCCESS';
export const GET_LANES_LIST_FAILED = 'GET_LANES_LIST_FAILED';

export const GET_LANE_DETAILS_START = 'GET_LANE_DETAILS_START';
export const GET_LANE_DETAILS_SUCCESS = 'GET_LANE_DETAILS_SUCCESS';
export const GET_LANE_DETAILS_FAILED = 'GET_LANE_DETAILS_FAILED';
export const GET_LANE_DETAILS_COMPLETED = 'GET_LANE_DETAILS_COMPLETED';

export const GET_LANE_STABILITY_START = 'GET_LANE_STABILITY_START';
export const GET_LANE_STABILITY_SUCCESS = 'GET_LANE_STABILITY_SUCCESS';
export const GET_LANE_STABILITY_FAILED = 'GET_LANE_STABILITY_FAILED';

export const GET_ROUTE_DETAILS_START = 'GET_ROUTE_DETAILS_START';
export const GET_ROUTE_DETAILS_SUCCESS = 'GET_ROUTE_DETAILS_SUCCESS';
export const GET_ROUTE_DETAILS_FAILED = 'GET_ROUTE_DETAILS_FAILED';

export const GET_ROUTE_STABILITY_START = 'GET_ROUTE_STABILITY_START';
export const GET_ROUTE_STABILITY_SUCCESS = 'GET_ROUTE_STABILITY_SUCCESS';
export const GET_ROUTE_STABILITY_FAILED = 'GET_ROUTE_STABILITY_FAILED';

export const GET_LEG_STABILITY_START = 'GET_LEG_STABILITY_START';
export const GET_LEG_STABILITY_SUCCESS = 'GET_LEG_STABILITY_SUCCESS';
export const GET_LEG_STABILITY_FAILED = 'GET_LEG_STABILITY_FAILED';
export const ASSIGN_DEVICE_TO_SHIPMENT_START = 'ASSIGN_DEVICE_TO_SHIPMENT_START';
export const ASSIGN_DEVICE_TO_SHIPMENT_SUCCESS = 'ASSIGN_DEVICE_TO_SHIPMENT_SUCCESS';
export const ASSIGN_DEVICE_TO_SHIPMENT_FAILED = 'ASSIGN_DEVICE_TO_SHIPMENT_FAILED';

export const GET_LANE_INSIGHTS_START = 'GET_LANE_INSIGHTS_START';
export const GET_LANE_INSIGHTS_SUCCESS = 'GET_LANE_INSIGHTS_SUCCESS';
export const GET_LANE_INSIGHTS_FAILED = 'GET_LANE_INSIGHTS_FAILED';

export const GET_ROUTE_INSIGHTS_START = 'GET_ROUTE_INSIGHTS_START';
export const GET_ROUTE_INSIGHTS_SUCCESS = 'GET_ROUTE_INSIGHTS_SUCCESS';
export const GET_ROUTE_INSIGHTS_FAILED = 'GET_ROUTE_INSIGHTS_FAILED';

export const GET_LEG_INSIGHTS_START = 'GET_LEG_INSIGHTS_START';
export const GET_LEG_INSIGHTS_SUCCESS = 'GET_LEG_INSIGHTS_SUCCESS';
export const GET_LEG_INSIGHTS_FAILED = 'GET_LEG_INSIGHTS_FAILED';

export const GET_ORIGINS_START = 'GET_ORIGINS_START';
export const GET_ORIGINS_SUCCESS = 'GET_ORIGINS_SUCCESS';
export const GET_ORIGINS_FAILED = 'GET_ORIGINS_FAILED';
export const GET_DESTINATIONS_START = 'GET_DESTINATIONS_START';
export const GET_DESTINATIONS_SUCCESS = 'GET_DESTINATIONS_SUCCESS';
export const GET_DESTINATIONS_FAILED = 'GET_DESTINATIONS_FAILED';

export const GET_USER_START = 'GET_USER_START';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILED = 'GET_USER_FAILED';

export const ENABLE_DISABLE_COMPANY = 'ENABLE_DISABLE_COMPANY';

export const UPDATE_USER_ROLE = 'UPDATE_USER_ROLE';
export const UPDATE_USER_ROLE_START = 'UPDATE_USER_ROLE_START';

export const MAKE_USER_POC = 'MAKE_USER_POC';

export const GET_USER_DETAILS_START = 'GET_USER_DETAILS_START';
export const GET_USER_DETAILS_SUCCESS = 'GET_USER_DETAILS_SUCCESS';
export const GET_USER_DETAILS_FAILED = 'GET_USER_DETAILS_FAILED';

export const GET_GROUP_LIST_LIMITED_START = 'GET_GROUP_LIST_LIMITED_START';
export const GET_GROUP_LIST_LIMITED = 'GET_GROUP_LIST_LIMITED';

export const GET_SUPERSET_DASHBOARD_DETAILS_SUCCESS = 'GET_SUPERSET_DASHBOARD_DETAILS_SUCCESS';


export const GET_ROUTE_EVENT_INSIGTHS_START = 'GET_ROUTE_EVENT_INSIGTHS_START';
export const GET_ROUTE_EVENT_INSIGTHS_SUCCESS = 'GET_ROUTE_EVENT_INSIGTHS_SUCCESS';
export const GET_ROUTE_EVENT_INSIGTHS_FAILED = 'GET_ROUTE_EVENT_INSIGTHS_FAILED';


export const GET_LEGS_EVENT_INSIGTHS_START = 'GET_LEGS_EVENT_INSIGTHS_START';
export const GET_LEGS_EVENT_INSIGTHS_SUCCESS = 'GET_LEGS_EVENT_INSIGTHS_SUCCESS';
export const GET_LEGS_EVENT_INSIGTHS_FAILED = 'GET_LEGS_EVENT_INSIGTHS_FAILED';


export const GET_EVENT_LIST_START = 'GET_EVENT_LIST_START';
export const GET_EVENT_LIST_SUCCESS = 'GET_EVENT_LIST_SUCCESS';
export const GET_EVENT_LIST_FAILED = 'GET_EVENT_LIST_FAILED';
export const EVENT_LIST_CLEAR = 'EVENT_LIST_CLEAR';


export const EVENT_DETAILS_START = 'EVENT_DETAILS_START';
export const EVENT_DETAILS_SUCCESS = 'EVENT_DETAILS_SUCCESS';
export const EVENT_DETAILS_FAILED = 'EVENT_DETAILS_FAILED';
export const EVENT_DETAILS_CLEAR = 'EVENT_DETAILS_CLEAR';


export const GET_LEG_EVENT_INSIGTHS_START = 'GET_LEG_EVENT_INSIGTHS_START';
export const GET_LEG_EVENT_INSIGTHS_SUCCESS = 'GET_LEG_EVENT_INSIGTHS_SUCCESS';
export const GET_LEG_EVENT_INSIGTHS_FAILED = 'GET_LEG_EVENT_INSIGTHS_FAILED';

export const EVENT_LIST_SEVERITY = 'EVENT_LIST_SEVERITY';


export const GET_LANE_TRANSIT_LIST_START = 'GET_LANE_TRANSIT_LIST_START';
export const GET_LANE_TRANSIT_LIST_SUCCESS = 'GET_LANE_TRANSIT_LIST_SUCCESS';
export const GET_LANE_TRANSIT_LIST_FAILED = 'GET_LANE_TRANSIT_LIST_FAILED';

// New Lane Details 

export const GET_NEW_LANE_DETAILS_START = 'GET_NEW_LANE_DETAILS_START';
export const GET_NEW_LANE_DETAILS_SUCCESS = 'GET_NEW_LANE_DETAILS_SUCCESS';
export const GET_NEW_LANE_DETAILS_FAILED = 'GET_NEW_LANE_DETAILS_FAILED';

export const GET_NEW_TEMPERATURE_START = 'GET_NEW_TEMPERATURE_START';
export const  GET_NEW_TEMPERATURE_SUCCESS = ' GET_NEW_TEMPERATURE_SUCCESS';
export const  GET_NEW_TEMPERATURE_FAILED = ' GET_NEW_TEMPERATURE_FAILED';

export const GET_NEW_PERFORMANCE_START = 'GET_NEW_PERFORMANCE_START';
export const GET_NEW_PERFORMANCE_SUCCESS = 'GET_NEW_PERFORMANCE_SUCCESS';
export const GET_NEW_PERFORMANCE_FAILED = 'GET_NEW_PERFORMANCE_FAILED';

export const GET_NEW_STABILITY_START = 'GET_NEW_STABILITY_START';
export const GET_NEW_STABILITY_SUCCESS = 'GET_NEW_STABILITY_SUCCESS';
export const GET_NEW_STABILITY_FAILED = 'GET_NEW_STABILITY_FAILED';

export const GET_NEW_ROUTE_START = 'GET_NEW_ROUTE_START';
export const GET_NEW_ROUTE_SUCCESS = 'GET_NEW_ROUTE_SUCCESS';
export const GET_NEW_ROUTE_FAILED = 'GET_NEW_START_FAILED';

export const GET_NEW_WAYPOINT_OR_SEGMENT_START = 'GET_NEW_WAYPOINT_OR_SEGMENT_START';
export const GET_NEW_WAYPOINT_OR_SEGMENT_SUCCESS = 'GET_NEW_WAYPOINT_OR_SEGMENT_SUCCESS';
export const GET_NEW_WAYPOINT_OR_SEGMENT_FAILED = 'GET_NEW_WAYPOINT_OR_SEGMENT_FAILED';

export const GET_NEW_SHIPMENT_TIME_START = 'GET_NEW_SHIPMENT_TIME_START';
export const GET_NEW_SHIPMENT_TIME_SUCCESS = 'GET_NEW_SHIPMENT_TIME_SUCCESS';
export const GET_NEW_SHIPMENT_TIME_FAILED = 'GET_NEW_SHIPMENT_TIME_FAILED';

export const GET_NEW_COMPARISON_START = 'GET_NEW_COMPARISON_START';
export const GET_NEW_COMPARISON_SUCCESS = 'GET_NEW_COMPARISON_SUCCESS';
export const GET_NEW_COMPARISON_FAILED = 'GET_NEW_COMPARISON_FAILED';

export const GET_NEW_EVENT_STABILITY_START = 'GET_NEW_EVENT_STABILITY_START';
export const GET_NEW_EVENT_STABILITY_SUCCESS = 'GET_NEW_EVENT_STABILITY_SUCCESS';
export const GET_NEW_EVENT_STABILITY_FAILED = 'GET_NEW_EVENT_STABILITY_FAILED';

export const GET_NEW_SHIPMENT_DETAILS_START = 'GET_NEW_SHIPMENT_DETAILS_START';
export const GET_NEW_SHIPMENT_DETAILS_SUCCESS = 'GET_NEW_SHIPMENT_DETAILS_SUCCESS';
export const GET_NEW_SHIPMENT_DETAILS_FAILED = 'GET_NEW_SHIPMENT_DETAILS_FAILED';


export const GET_NEW_EVENT_COUNT_START = 'GET_NEW_EVENT_COUNT_START';
export const GET_NEW_EVENT_COUNT_SUCCESS = 'GET_NEW_EVENT_COUNT_SUCCESS';
export const GET_NEW_EVENT_COUNT_FAILED = 'GET_NEW_EVENT_COUNT_FAILED';


export const GET_NEW_DEVICE_PERFPORMANCE_START = 'GET_NEW_DEVICE_PERFPORMANCE_START';
export const GET_NEW_DEVICE_PERFPORMANCE_SUCCESS = 'GET_NEW_DEVICE_PERFPORMANCE_SUCCESS';
export const GET_NEW_DEVICE_PERFPORMANCE_FAILED = 'GET_NEW_DEVICE_PERFPORMANCE_FAILED';

export const GET_NEW_CO2_EMISSIONS_START = 'GET_NEW_CO2_EMISSIONS_START';
export const GET_NEW_CO2_EMISSIONS_SUCCESS = 'GET_NEW_CO2_EMISSIONS_SUCCESS';
export const GET_NEW_CO2_EMISSIONS_FAILED = 'GET_NEW_CO2_EMISSIONS_FAILED';

export const EVENT_TYPE_MAP = 'EVENT_TYPE_MAP';

export const GET_ATHENA_INSIGHT_START = 'GET_ATHENA_INSIGHT_START';
export const GET_ATHENA_INSIGHT_SUCCESS = 'GET_ATHENA_INSIGHT_SUCCESS';
export const GET_ATHENA_INSIGHT_FAILED = 'GET_ATHENA_INSIGHT_FAILED';
export const GET_ATHENA_INSIGHT_ERROR_HANDLING = 'GET_ATHENA_INSIGHT_ERROR_HANDLING';

export const CLEAR_FINAL_SHIPMENT_DATA = 'CLEAR_FINAL_SHIPMENT_DATA';